import React, { SyntheticEvent, useCallback, useRef, useState } from 'react';
import cn from 'classnames';

import { useResponsive } from 'client/hooks';
import { ACTIONS, fireGAEvent } from 'client/utils/gtag';
import { timeline_data } from './timeline-data';
import { childT, TimelineItem } from './TimelineItem';
import { vwDSizes } from 'client/utils/vwSizes';
import { Icon } from 'client/components/common';
import useDraggableScroll from 'use-draggable-scroll';

import bg from 'client/assets/FourthSection/bg.png';

import css from './FourthSection.module.scss';

export const FourthSection = () => {
  const [activeItemTitle, setActiveItemTitle] = useState<string>('');
  const timelineRef = useRef<HTMLDivElement>(null);
  const { onMouseDown } = useDraggableScroll(timelineRef);
  const [isMobile] = useResponsive('MOBILE');

  const onActiveItemSet = (title: string, date: string, index: number) => {
    setActiveItemTitle(title);

    if (window && timelineRef.current && isMobile) {
      const windowW = window.innerWidth;
      const totalItems = timeline_data.length;
      const timelineWIdth = timelineRef.current?.scrollWidth;
      let percentsFromLeft = 0;

      if (index < 10) percentsFromLeft = 32.5;
      if (index >= 10 && index <= 20) percentsFromLeft = 31.5;
      if (index > 20) percentsFromLeft = 31;
      timelineRef.current.scrollTo({
        top: 0,
        left: -(
          timelineWIdth -
          (timelineWIdth / totalItems) * (totalItems - index) -
          (windowW / 100) * percentsFromLeft
        ),
        behavior: 'smooth',
      });
    }

    fireGAEvent({ Action: ACTIONS.TIMELINE_CLICK, Label: `${date} - ${title}` });
  };

  const onArrowClick = useCallback(
    (direction: 'left' | 'right') => () => {
      if (timelineRef.current) {
        const options: ScrollToOptions = {
          top: 0,
          left: direction === 'left' ? -vwDSizes(225) : vwDSizes(225),
          behavior: 'smooth',
        };

        timelineRef.current.scrollBy(options);
      }
    },
    [timelineRef],
  );

  return (
    <section className={css.fourthSection}>
      <div className={css.title}>
        <span>{TEXTS.title}</span>
      </div>
      <div className={css.background}>
        <img src={bg} alt="" />
      </div>
      <button className={cn(css.arrowRight, false && css.inactive)} onClick={onArrowClick('right')}>
        <Icon type="white-arrow-right" />
      </button>
      <div className={css.timeline} ref={timelineRef} onMouseDown={onMouseDown}>
        <div className={css.slider}>
          {timeline_data.map((item, index) => {
            let childClassName: childT = '';

            if (index === 0) {
              childClassName = 'first';
            } else if (index === 1) {
              childClassName = 'second';
            } else if (index === timeline_data.length - 1) {
              childClassName = 'last';
            } else {
              childClassName = '';
            }

            return (
              <div className={css.item} key={`${item.date}&${index}`}>
                <div className={css.whiteLine} />
                <TimelineItem
                  {...item}
                  key={`${item.date}/${index}`}
                  isActive={activeItemTitle === item.title}
                  onClick={onActiveItemSet}
                  className={css.slide}
                  child={childClassName}
                  index={index}
                />
                {index === timeline_data.length - 1 && <div className={css.whiteLine} />}
              </div>
            );
          })}
        </div>
      </div>
      <button className={cn(css.arrowLeft, false && css.inactive)} onClick={onArrowClick('left')}>
        <Icon type="white-arrow-left" />
      </button>
    </section>
  );
};

const TEXTS = {
  title: 'כרונולוגיה של ביצועים: נקודות ציון בהיסטוריה של חטיבת M',
};
