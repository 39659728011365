import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as actions from './actions';
import { UI } from './types';

const initialState: UI = {
  page: 'intro',
  userData: {
    gender: 'default',
    textMessageIndex: 0,
    audioFileName: '',
  },
};

export const settings = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<actions.SetPage>) => {
      state.page = action.payload;
    },
    setUserData: (state, action: PayloadAction<actions.SetUserData>) => {
      if (action.payload.audioFileName) state.userData.audioFileName = action.payload.audioFileName;
      if (action.payload.gender) state.userData.gender = action.payload.gender;
      if (action.payload.textMessageIndex) state.userData.textMessageIndex = action.payload.textMessageIndex;
    },
    resetUserData: (state) => {
      state.userData = initialState.userData;
    },
  },
});

export const { setPage, setUserData, resetUserData } = settings.actions;
export default settings.reducer;
