import React, { FC } from 'react';
import cn from 'classnames';

import { ACTIONS, fireGAEvent } from 'client/utils/gtag';

import css from './Article.module.scss';

type Props = {
  title: string;
  subtitle: string;
  lastWords: string;
  link: string;
  imgUrl: string;
  className?: string;
};
export const Article: FC<Props> = (props) => {
  const { title, subtitle, lastWords, link, imgUrl, className = '' } = props;

  const onArticleClick = () => {
    fireGAEvent({ Action: ACTIONS.SECONDARY_ARTICLES_CLICK, Label: title });
  };

  return (
    <a className={cn(css.article, className)} onClick={onArticleClick} href={link} target="_blank" rel="noreferrer">
      <div className={css.imgWrapper}>
        <img src={imgUrl} alt="" />
      </div>
      <div className={css.texts}>
        <span className={css.title}>{title}</span>
        <p className={css.subtitle}>
          {subtitle} <span>{lastWords}</span>
        </p>
      </div>
    </a>
  );
};
