import React from 'react';
import classNames from 'classnames';

import css from './Icon.module.scss';

export type IconType =
  | 'share'
  | 'facebook'
  | 'instagram'
  | 'whatsup'
  | 'envelope'
  | 'arrow-down'
  | 'play'
  | 'pause'
  | 'mute'
  | 'arrow-left'
  | 'white-arrow-right'
  | 'white-arrow-left'
  | 'unmute'
  | 'big-play'
  | 'close'
  | 'big-pause';

interface Props {
  type: IconType;
  className?: string;
  width?: number;
  height?: number;
  color?: string;
  secondaryColor?: string;
  click?: () => void;
}

const Icon = ({ type, className, width, height, color = '#000', click, secondaryColor, ...iconProps }: Props) => {
  let icon = null;
  const svgProps = {
    width,
    height,
    viewBox: `0 0 ${width || 38} ${height || 38}`,
    xmlns: 'http://www.w3.org/2000/svg',
  };

  switch (type) {
    case 'close':
      icon = (
        <svg
          {...svgProps}
          xmlns="http://www.w3.org/2000/svg"
          width="41.133"
          height="41.133"
          viewBox="0 0 41.133 41.133"
        >
          <path
            data-name="Path 44"
            d="M19.45-23.56h12.271v4.11H19.45v12.689h-4.14V-19.45H3.1v-4.11h12.21v-12.748h4.14z"
            transform="rotate(45 -31.832 3.327)"
            fill="#fff"
          />
        </svg>
      );
      break;

    case 'share':
      icon = (
        <svg {...svgProps} xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
          <g id="Group_94" data-name="Group 94" transform="translate(-353 -34.5)">
            <g id="Group_92" data-name="Group 92" transform="translate(104 0.5)">
              <circle
                id="Ellipse_10"
                data-name="Ellipse 10"
                fill="#fff"
                cx="13.5"
                cy="13.5"
                r="13.5"
                transform="translate(249 34)"
              />
            </g>
            <path
              id="Icon_material-share"
              data-name="Icon material-share"
              d="M16.89,14.63a2.4,2.4,0,0,0-1.619.636l-5.89-3.428a2.286,2.286,0,0,0,0-1.156l5.823-3.395a2.473,2.473,0,1,0-.793-1.809,2.7,2.7,0,0,0,.074.578L8.663,9.451a2.478,2.478,0,1,0,0,3.618l5.881,3.436a2.33,2.33,0,0,0-.066.537A2.412,2.412,0,1,0,16.89,14.63Z"
              transform="translate(354.048 37)"
            />
          </g>
        </svg>
      );
      break;

    case 'facebook':
      icon = (
        <svg {...svgProps} xmlns="http://www.w3.org/2000/svg" width="27" height="26.888" viewBox="0 0 27 26.888">
          <path
            d="M13.5.349a13.493 13.493 0 0 0-2.252 26.8V16.666H7.992v-3.772h3.257v-2.781c0-3.227 1.971-4.985 4.85-4.985a26.876 26.876 0 0 1 2.908.148v3.373h-2c-1.565 0-1.867.744-1.867 1.835v2.407h3.735l-.487 3.772h-3.246v10.574A13.494 13.494 0 0 0 13.5.349z"
            transform="translate(0 -.349)"
            fill="#fff"
          />
        </svg>
      );
      break;

    case 'instagram':
      icon = (
        <svg {...svgProps} xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
          <g data-name="Group 78" transform="translate(-249 -34)">
            <circle data-name="Ellipse 10" cx="13.5" cy="13.5" r="13.5" transform="translate(249 34)" fill="#fff" />
            <g data-name="Icon ionic-logo-instagram">
              <path
                data-name="Path 36"
                d="M266.278 40.444a3.622 3.622 0 0 1 3.611 3.611v7.222a3.622 3.622 0 0 1-3.611 3.611h-7.222a3.622 3.622 0 0 1-3.611-3.611v-7.221a3.622 3.622 0 0 1 3.611-3.612h7.222m0-1.444h-7.222A5.07 5.07 0 0 0 254 44.056v7.222a5.07 5.07 0 0 0 5.056 5.056h7.222a5.07 5.07 0 0 0 5.056-5.056v-7.222A5.07 5.07 0 0 0 266.278 39z"
              />
              <path
                data-name="Path 37"
                d="M267.361 44.056a1.083 1.083 0 1 1 1.083-1.083 1.081 1.081 0 0 1-1.083 1.083z"
              />
              <path
                data-name="Path 38"
                d="M262.666 44.777a2.889 2.889 0 1 1-2.889 2.889 2.892 2.892 0 0 1 2.889-2.889m0-1.444a4.333 4.333 0 1 0 4.333 4.333 4.334 4.334 0 0 0-4.333-4.333z"
              />
            </g>
          </g>
        </svg>
      );
      break;

    case 'whatsup':
      icon = (
        <svg {...svgProps} xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
          <path
            d="M14249 6984.5a13.5 13.5 0 1 1 13.5 13.5 13.5 13.5 0 0 1-13.5-13.5zm5.705-.224a8.063 8.063 0 0 0 1.08 4.04l-1.146 4.185 4.282-1.125a8.092 8.092 0 0 0 3.861.986 8.08 8.08 0 1 0-8.08-8.086zm8.077 6.719a6.7 6.7 0 0 1-3.417-.935l-.244-.145-2.544.666.681-2.477-.161-.254a6.716 6.716 0 1 1 5.688 3.145zm-3.4-10.194a2.257 2.257 0 0 0-.705 1.682 3.948 3.948 0 0 0 .823 2.09 9.022 9.022 0 0 0 3.449 3.044 11.374 11.374 0 0 0 1.149.426 2.718 2.718 0 0 0 1.272.079 2.067 2.067 0 0 0 1.361-.958 1.675 1.675 0 0 0 .118-.958c-.052-.084-.185-.136-.387-.235s-1.2-.59-1.38-.657-.32-.1-.453.1-.523.656-.637.792-.237.151-.44.048a5.474 5.474 0 0 1-1.623-1 6.013 6.013 0 0 1-1.125-1.4c-.115-.2 0-.3.091-.412a5.962 5.962 0 0 0 .5-.692.372.372 0 0 0-.019-.354c-.048-.1-.453-1.092-.623-1.5-.146-.348-.293-.348-.41-.348h-.043c-.117-.006-.251-.006-.387-.006a.744.744 0 0 0-.527.258z"
            transform="translate(-14248.999 -6971)"
            fill="#fff"
          />
        </svg>
      );
      break;

    case 'envelope':
      icon = (
        <svg {...svgProps} xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
          <g id="Group_106" data-name="Group 106" transform="translate(-353 -34)">
            <g id="Group_79" data-name="Group 79" transform="translate(104)">
              <circle
                id="Ellipse_10"
                data-name="Ellipse 10"
                cx="13.5"
                cy="13.5"
                r="13.5"
                transform="translate(249 34)"
                fill="#fff"
              />
            </g>
            <g id="Icon_feather-mail" data-name="Icon feather-mail" transform="translate(356 36)">
              <path
                id="Path_39"
                data-name="Path 39"
                d="M4.55,6h12.4A1.453,1.453,0,0,1,18.5,7.32v7.917a1.453,1.453,0,0,1-1.55,1.32H4.55A1.453,1.453,0,0,1,3,15.237V7.32A1.453,1.453,0,0,1,4.55,6Z"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <path
                id="Path_40"
                data-name="Path 40"
                d="M18.5,9l-7.75,4.618L3,9"
                transform="translate(0 -1.68)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
          </g>
        </svg>
      );
      break;

    case 'arrow-down':
      icon = (
        <svg
          {...svgProps}
          xmlns="http://www.w3.org/2000/svg"
          width="34.115"
          height="33.007"
          viewBox="0 0 34.115 33.007"
          onClick={click}
        >
          <g data-name="Group 64">
            <path
              data-name="Union 1"
              d="m0 15.949 1.976-1.976 15.082 15.081 15.081-15.081 1.976 1.976-17.057 17.058zM0 1.976 1.975 0l15.083 15.081L32.139 0l1.976 1.976-17.057 17.058z"
              fill="#fff"
            />
          </g>
        </svg>
      );
      break;

    case 'arrow-left':
      icon = (
        <svg
          {...svgProps}
          xmlns="http://www.w3.org/2000/svg"
          width="14.946"
          height="15.448"
          viewBox="0 0 14.946 15.448"
        >
          <g data-name="Group 99">
            <path
              data-name="Union 1"
              d="m0 7.222.895-.895 6.83 6.829 6.829-6.829.895.895-7.725 7.724zM0 .895.895 0l6.83 6.829L14.553 0l.895.895-7.724 7.724z"
              transform="rotate(90 7.473 7.473)"
              fill="#ff4d40"
            />
          </g>
        </svg>
      );
      break;

    case 'white-arrow-left':
      icon = (
        <svg {...svgProps} xmlns="http://www.w3.org/2000/svg" width="9.153" height="16.009" viewBox="0 0 9.153 16.009">
          <path
            data-name="Icon ionic-ios-arrow-back"
            d="m14.01 14.2 6.058-6.053a1.144 1.144 0 0 0-1.62-1.616l-6.863 6.858a1.142 1.142 0 0 0-.033 1.578l6.892 6.906a1.144 1.144 0 1 0 1.62-1.616z"
            transform="translate(-11.251 -6.194)"
            fill="#fff"
          />
        </svg>
      );
      break;

    case 'white-arrow-right':
      icon = (
        <svg {...svgProps} xmlns="http://www.w3.org/2000/svg" width="9.153" height="16.01" viewBox="0 0 9.153 16.01">
          <path
            data-name="Icon ionic-ios-arrow-back"
            d="m17.644 14.2-6.057-6.056a1.144 1.144 0 0 1 1.62-1.616l6.863 6.858a1.142 1.142 0 0 1 .033 1.578l-6.891 6.906a1.144 1.144 0 0 1-1.62-1.616z"
            transform="translate(-11.251 -6.194)"
            fill="#fff"
          />
        </svg>
      );
      break;

    case 'play':
      icon = (
        <svg {...svgProps} xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
          <g data-name="Group 70" transform="translate(-29 -692)">
            <circle data-name="Ellipse 7" cx="13.5" cy="13.5" r="13.5" transform="translate(29 692)" fill="#fff" />
            <path data-name="Polygon 4" d="m5 0 5 9H0z" transform="rotate(90 -326 375)" fill="#e02618" />
          </g>
        </svg>
      );
      break;

    case 'pause':
      icon = (
        <svg {...svgProps} xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
          <g id="pause_" data-name="pause " transform="translate(-29 -692)">
            <circle
              id="Ellipse_7"
              data-name="Ellipse 7"
              cx="13.5"
              cy="13.5"
              r="13.5"
              transform="translate(29 692)"
              fill="#fff"
            />
            <rect id="Rectangle_46" data-name="Rectangle 46" width="4" height="12" transform="translate(37 700)" />
            <rect id="Rectangle_47" data-name="Rectangle 47" width="4" height="12" transform="translate(44 700)" />
          </g>
        </svg>
      );
      break;

    case 'mute':
      icon = (
        <svg {...svgProps} xmlns="http://www.w3.org/2000/svg" width="12" height="18" viewBox="0 0 12 18">
          <path
            id="UNMUTE"
            d="M10.08,4.831,5.909,9H1.125A1.125,1.125,0,0,0,0,10.125v6.75A1.125,1.125,0,0,0,1.125,18H5.909l4.17,4.17a1.126,1.126,0,0,0,1.92-.8V5.626A1.126,1.126,0,0,0,10.08,4.831Z"
            transform="translate(0 -4.5)"
            fill="#fff"
          />
        </svg>
      );
      break;

    case 'unmute':
      icon = (
        <svg {...svgProps} xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18">
          <path
            data-name="Icon awesome-volume-mute"
            d="M10.08 4.831 5.909 9H1.125A1.125 1.125 0 0 0 0 10.125v6.75A1.125 1.125 0 0 0 1.125 18h4.784l4.17 4.17a1.126 1.126 0 0 0 1.92-.8V5.626a1.126 1.126 0 0 0-1.919-.795zm11.56 8.67 2.139-2.139a.757.757 0 0 0 0-1.07l-1.07-1.07a.757.757 0 0 0-1.07 0L19.5 11.361l-2.139-2.14a.757.757 0 0 0-1.07 0l-1.07 1.07a.757.757 0 0 0 0 1.07l2.14 2.139-2.139 2.139a.757.757 0 0 0 0 1.07l1.07 1.07a.757.757 0 0 0 1.07 0l2.138-2.14 2.139 2.139a.757.757 0 0 0 1.07 0l1.07-1.07a.757.757 0 0 0 0-1.07z"
            transform="translate(0 -4.5)"
            fill="#fff"
          />
        </svg>
      );
      break;

    case 'big-play':
      icon = (
        <svg {...svgProps} xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68">
          <g id="Group_88" data-name="Group 88" transform="translate(-23 -742)" opacity="0.67">
            <circle
              id="Ellipse_7"
              data-name="Ellipse 7"
              cx="34"
              cy="34"
              r="34"
              transform="translate(23 742)"
              fill="#fff"
            />
            <path
              id="Path_31"
              data-name="Path 31"
              d="M16.213,0,32.425,28.822H0Z"
              transform="translate(77.042 760.014) rotate(90)"
            />
          </g>
        </svg>
      );
      break;
    case 'big-pause':
      icon = (
        <svg {...svgProps} xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68">
          <g id="pause" transform="translate(-23 -742)" opacity="0.67">
            <circle
              id="Ellipse_7"
              data-name="Ellipse 7"
              cx="34"
              cy="34"
              r="34"
              transform="translate(23 742)"
              fill="#fff"
            />
            <g id="Group_108" data-name="Group 108" transform="translate(1)">
              <rect
                id="Rectangle_48"
                data-name="Rectangle 48"
                width="8"
                height="23"
                transform="translate(45 765)"
                fill="#030006"
              />
              <rect
                id="Rectangle_49"
                data-name="Rectangle 49"
                width="8"
                height="23"
                transform="translate(59 765)"
                fill="#030006"
              />
            </g>
          </g>
        </svg>
      );
      break;
    default:
      break;
  }

  return (
    <i className={classNames(css.icon, className)} {...iconProps}>
      {icon}
    </i>
  );
};

Icon.defaultProps = {
  className: '',
  width: 38.945,
  height: 38.948,
  color: '#111',
  secondaryColor: '#000',
};

export default Icon;
