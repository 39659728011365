import React, { useEffect, useRef } from 'react';

import { Icon } from 'client/components/common';
import { VIDEO_URLS } from 'client/constants/video-links';

import { useResponsive } from 'client/hooks';

import titleImg from 'client/assets/header/title_img.png';
import mobileLogo from 'client/assets/header/ynet_mobile.png';

import css from './FirstSection.module.scss';

export const FirstSection = () => {
  const playerRef = useRef<HTMLVideoElement>(null);
  const [isMobile] = useResponsive('MOBILE');

  const clickOnArrows = () => {
    const element = document.getElementById('second-section');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  };

  useEffect(() => {
    let interval: any;

    const replay = async () => {
      if (playerRef.current) {
        await playerRef.current.play();

        interval = setInterval(async () => {
          if (playerRef.current) {
            playerRef.current.load();
            await playerRef.current.play();
          }
        }, 36000);

        return () => interval && clearInterval(interval);
      }
    };

    replay();
  }, [playerRef.current]);

  return (
    <section className={css.firstSection}>
      <div className={css.background}>
        <video
          src={isMobile ? VIDEO_URLS.mobile.header : VIDEO_URLS.desktop.header}
          playsInline
          muted
          autoPlay
          ref={playerRef}
        ></video>
      </div>
      <div className={css.content}>
        <div className={css.center}>
          <img src={titleImg} alt="" />
          <span className={css.title}>{TEXTS.title}</span>
          <div className={css.mobileLogo}>
            <span>{TEXTS.logoText}</span>
            <img src={mobileLogo} alt="" />
          </div>
          <p
            className={css.subtitle}
            dangerouslySetInnerHTML={{ __html: isMobile ? TEXTS.subtitleMob : TEXTS.subtitleDesk }}
          ></p>
          <Icon type="arrow-down" className={css.arrowDown} click={clickOnArrows} />
        </div>
      </div>
    </section>
  );
};

const TEXTS = {
  title: 'ברוכים הבאים לעולם ה-M של BMW',
  subtitleDesk:
    '50 שנה לחטיבת הספורט והביצועים של המותג BMW לא הולכות ברגל, תרתי משמע.<br/>8 סדרות רכבים, עשרות דגמים ומאות סיפורים מרגשים. ואם ככה נראו 50 השנים הראשונות, אפשר רק לדמיין מה יהיה ב-50 השנים הבאות.<br/><span>אנחנו מזמינים אתכם לסייר במתחם המיוחד ולגלות איך הכול התחיל; רגעים היסטוריים, ציוני דרך מרהיבים - והדובדבן שבקצפת, סיפורו של ד"ר דורון קרייזר, גניקולוג ביום, נהג מרוצים בלילה, בסדרת וידאו מעוררת השראה.</span>',
  subtitleMob: `50 שנה לחטיבת הספורט והביצועים של המותג BMW לא הולכות ברגל, תרתי משמע.<br/>
    אנחנו מזמינים אתכם לסייר במתחם המיוחד ולגלות איך הכול התחיל; רגעים היסטוריים, ציוני דרך מרהיבים - והדובדבן שבקצפת, סיפורו של ד"ר דורון קרייזר, גניקולוג ביום, נהג מרוצים בלילה, בסדרת וידאו מעוררת השראה.`,
  logoText: 'בשיתוף',
};
