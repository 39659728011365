import firstImage from 'client/assets/ThirdSection/firstImage.png';
import secondImage from 'client/assets/ThirdSection/secondImage.jpg';
import thirdImage from 'client/assets/ThirdSection/thirdImage.jpeg';
import fourthImage from 'client/assets/ThirdSection/fourthImage.jpg';
import fifthImage from 'client/assets/ThirdSection/fifthImage.jpg';
import sixthImage from 'client/assets/ThirdSection/sixthImage.jpg';

type ArticleT = {
  title: string;
  subtitle: string;
  lastWords: string;
  link: string;
  imgUrl: string;
};
export const articles_data: Array<ArticleT> = [
  {
    title: 'גינקולוג ביום, נהג מרוצים בלילה: התחביב של ד"ר דורון קרייזר',
    subtitle: `מגיל 5 אהב ד"ר דורון קרייזר לתקן מכוניות. במרוצת השנים הוא הפך לרופא נשים רציני, עם מעט שעות שינה, אבל לא ויתר על החלום. הוא עלה על מסלול המרוצים, עבר ל-BMW ובגיל 50 הגיע לשיא האקשן - נהיגת דריפט: "כמו מקרי חירום בחדר לידה". גם בזה הוא לא הסתפק ובימים אלה הוא בונה עם BMW מכונית מרוץ משלו: "רציתי כוס חלב ובסוף קניתי את הפרה כולה". ומה חושבת המשפחה? "תומכים, אבל לא רצים איתי למוסך".`,
    lastWords: 'ריאיון מיוחד',
    link: 'https://www.ynet.co.il/wheels/MBMW50YEARS/article/rygg113m3',
    imgUrl: firstImage,
  },
  {
    title: 'פגשנו את האיש מאחורי השרירים של רכבי ב.מ.וו ספורט',
    subtitle:
      'אחרי עשור בניהול פרויקטים שונים בקבוצת BMW מקבל טימו רש את הניהול של M Sport בתקופת מעבר מרתקת להנעה החשמלית. רוני נאק פגש את אחת הדמויות המעניינות בתעשיית הרכב העולמית וחזר עם תובנות חדשות על האתגרים בניהול מותג ביצועים כה חזק בתקופה של מהפכה טכנולוגית. "אופטימיים לגבי 50 השנים הבאות" - ',
    lastWords: 'מגזין',
    link: 'https://www.ynet.co.il/wheels/MBMW50YEARS/article/sy00ql11ydh',
    imgUrl: secondImage,
  },
  {
    title: 'ד"ר דריפט: הפודקאסט מאחורי פרויקט E46',
    subtitle: `הפודקאסט 'ד"ר דריפט' של ynet בשיתוף BMW עוקב אחר פרויקט E46 של ד"ר דורון קרייזר, מרגע הרכישה של דגם BMW ישן ועד להפיכתו למכונית דריפט מעוצבת. מה הביא את ד"ר קרייזר אל עולם הרכב והנהיגה? ואיך ההפתעה שעשתה דורית קרייזר לבעלה מתניעה מסע שלם? `,
    lastWords: `בינג' בשלושה פרקים`,
    link: 'https://www.ynet.co.il/wheels/MBMW50YEARS/article/hjilbxcln',
    imgUrl: thirdImage,
  },
  {
    title: 'ב.מ.וו M5: שושלת המלוכה, ויורשת העצר',
    subtitle:
      'היא אחת מהמכוניות היותר נחשקות שהרבה כסף יכול לקנות, כזאת שמשלבת בין ביצועים של מכוניות על אקזוטיות לבין הנוחות שתרצו לקבל ממכונית סלון משובחת. נהגנו בכל אחד מששת הדורות הקודמים של ב.מ.וו M5, כדי להתרשם מהאבולוציה שהיא עברה לאורך השנים, רגע לפני שהיא תסתייע בחשמל לשדרוג הביצועים',
    lastWords: '',
    link: 'https://www.ynet.co.il/wheels/article/r1y9w18to',
    imgUrl: fifthImage,
  },
  {
    title: 'ב.מ.וו M240i - אושר מוטורי גדול',
    subtitle:
      'דרה 2 החדשה מוכיחה שבמינכן עדיין יודעים לייצר מכוניות נהיגה לא רק תחת חטיבת M. לקחנו את הבכירה במשפחה לכבישי נהיגה מאתגרים כדי לבחון עד כמה זה נכון. ',
    lastWords: 'מבחן דרכים',
    link: 'https://www.ynet.co.il/wheels/article/h1lvo00ugi',
    imgUrl: fourthImage,
  },
  {
    title: 'עם הפרונט לעתיד: איך תראה מפת התחבורה בישראל בשנת 2050',
    subtitle:
      'מכוניות מעופפות, נתיבים רב מפלסיים בתוך מגה-ערים ובינה מלאכותית השולטת בכל. אנו בתחילתו של תהליך שינוי ארוך שאת תוצאותיו נראה בעוד כמה עשורים. איך נתנייד בכבישים בשנת 2050 וכיצד זה ישפיע על כולנו? ',
    lastWords: 'הפרטים לפניכם',
    link: 'https://www.ynet.co.il/wheels/MBMW50YEARS/article/r16rpapeh',
    imgUrl: sixthImage,
  },
];
