import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga';

import 'client/styles/index.scss';

import { MAIN_PAGE_ROUTE } from 'client/constants/routes';
import shareImg from 'client/assets/share.png';
import favicon from 'client/assets/favicon/favicon.png';

import Main from './pages/Main';

import css from './App.module.scss';

ReactGA.initialize('UA-XXXXXXXXXX-XX', process.env.MODE === 'local' ? { debug: true } : {});

const App: React.FC = () => {
  React.useEffect(() => {
    removeRootPreloader();
  }, []);

  const removeRootPreloader = () => {
    const preloadingScreen: HTMLElement | null = document.querySelector('.main-preloader');

    if (preloadingScreen) {
      setTimeout(() => {
        preloadingScreen.style.opacity = '0';
        preloadingScreen.style.visibility = 'hidden';
        preloadingScreen.style.pointerEvents = 'none';
      }, 500);
      setTimeout(() => {
        if (document && document.body) {
          document?.querySelector('.main-preloader')?.remove();
        }
      }, 1500);
    }
  };

  const renderHelmet = () => {
    const seo = {
      title: 'ברוכים הבאים לעולם ה-M – חטיבת הספורט של BMW',
      description:
        'במתחם תוכלו למצוא מידע אודות ההיסטוריה של חטיבת הספורט, דגמי המותג, מבחני דרכים ומגוון כתבות וקישורים למותג שחרט על דגלו את חוויית הנהיגה המושלמת',
    };
    const og = {
      title: 'ברוכים הבאים לעולם ה-M – חטיבת הספורט של BMW',
      description:
        'במתחם תוכלו למצוא מידע אודות ההיסטוריה של חטיבת הספורט, דגמי המותג, מבחני דרכים ומגוון כתבות וקישורים למותג שחרט על דגלו את חוויית הנהיגה המושלמת',
      image: shareImg,
    };

    return (
      <Helmet>
        <title>{seo.title}</title>
        <link rel="icon" type="image/png" href={favicon} />
        <meta name="description" content={seo.description} />
        <meta property="og:title" content={og.title} />
        <meta property="og:description" content={og.description} />
        <meta property="og:image" content={og.image} />
      </Helmet>
    );
  };

  return (
    <div className={css.app}>
      {renderHelmet()}
      <Switch>
        <Route exact path={MAIN_PAGE_ROUTE}>
          <Main />
        </Route>

        <Redirect to={MAIN_PAGE_ROUTE} />
      </Switch>
    </div>
  );
};

export default App;
