import React, { FC, useRef } from 'react';
import cn from 'classnames';

import { Icon } from 'client/components/common';
import useOnClickOutside from 'client/hooks/click-outside';

import css from './GalleryImagePopup.module.scss';

type Props = {
  imgUrl: string;
  onClose: () => void;
};

export const GalleryImagePopup: FC<Props> = ({ imgUrl, onClose }) => {
  const popupRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(popupRef, onClose);

  return (
    <div className={cn(css.popup, imgUrl && css.show)}>
      <div className={css.content} ref={popupRef}>
        <div className={css.close} onClick={onClose}>
          <Icon type="close" />
        </div>
        <img src={imgUrl} alt="" />
      </div>
    </div>
  );
};
