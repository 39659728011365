import poster1Desk from 'client/assets/posters/poster-1-desk.jpg';
import poster1Mob from 'client/assets/posters/poster-1-mob.jpg';
import poster2Desk from 'client/assets/posters/poster-2-desk.jpg';
import poster2Mob from 'client/assets/posters/poster-2-mob.jpg';
import poster3Desk from 'client/assets/posters/poster-3-desk.jpg';
import poster3Mob from 'client/assets/posters/poster-3-mob.jpg';

export const episodes_data = {
  first: {
    articleUrl: 'https://www.ynet.co.il/wheels/MBMW50YEARS/article/h1drbossn',
    video: {
      desktop: 'https://progressive-video.ynet.co.il/0923/5a9c659f83ee9069644d8a0875ef1d53-hd720.mp4',
      mobile: 'https://progressive-video.ynet.co.il/0923/5a9c659f83ee9069644d8a0875ef1d53-hd720.mp4',
    },
    posters: {
      desktop: poster1Desk,
      mobile: poster1Mob,
    },
    info: {
      title: `להפוך מכונית ב.מ.וו רגילה - לרכב מרוצים: החלום של ד"ר דורון קרייזר.`,
      subtitle: `אם תפגשו את ד"ר קרייזר ביום, סביר שתכירו רופא נשים מקצועי ומעונב המדבר בשקט ובביטחון. אבל בזמנו הפנוי הוא איש בעל תשוקה לכאוס בנישה ייחודית בעולם מרוצי המכוניות. עכשיו ד"ר קרייזר לא מסתפק בזה ויוצא למסע מאתגר במיוחד - בניית מכונית מרוץ משלו. הצטרפו אלינו למסע - הפרק הראשון`,
    },
    btnTitle: 'לסיפור המלא- פרק ראשון',
  },
  second: {
    articleUrl: 'https://www.ynet.co.il/wheels/MBMW50YEARS/article/b12cu00sh3',
    video: {
      desktop: 'https://progressive-video.ynet.co.il/0923/412ab4735991848e73b033f2467bae41-hd720.mp4',
      mobile: 'https://progressive-video.ynet.co.il/0923/412ab4735991848e73b033f2467bae41-hd720.mp4',
    },
    posters: {
      desktop: poster2Desk,
      mobile: poster2Mob,
    },
    info: {
      title: '"זו תהיה המכונית הכי יפה בעולם":',
      subtitle: `המסע להגשמת החלום של ד"ר קרייזר מתחיל לרקום עור וגידים. איך המשפחה מגיבה על התחביב שיצא משליטה, אילו אתגרים ותקלות בלתי צפויות צריך הד"ר לעבור בדרך ואיזה שחקן חיזוק מצטרף כדי לעצב את מכונית החלומות שלו. ממשיכים במסע – הפרק השני`,
    },
    btnTitle: 'לסיפור המלא- פרק שני',
  },
  third: {
    articleUrl: 'https://www.ynet.co.il/wheels/MBMW50YEARS/article/h1gzscplh',
    video: {
      desktop: 'https://progressive-video.ynet.co.il/0923/c2efd3774226236fa45707929a795c95-hd720.mp4',
      mobile: 'https://progressive-video.ynet.co.il/0923/c2efd3774226236fa45707929a795c95-hd720.mp4',
    },
    posters: {
      desktop: poster3Desk,
      mobile: poster3Mob,
    },
    info: {
      title: '"הגשמתי חלום": ד"ר קרייזר משיק רכב מרוצים שבנה בכוחות עצמו',
      subtitle: `אחרי שנה וחצי של עבודה, מאות שעות של השקעה ו-15 אנשי מקצוע שמלווים אותו בדרך, ד"ר קרייזר זוכה להשיק את רכב מרוצי הדריפט שבנה לעצמו. מהן התחושות אחרי תהליך מפרך שכזה? הפרק השלישי והמסכם בסדרת ה-E46 כבר כאן. צפו`,
    },
    btnTitle: 'לסיפור המלא- פרק אחרון',
  },
};
