import { CONFIG } from 'constants/index';

const CAN_USE_DOM = typeof window !== 'undefined';

export type ShareType = 'Facebook' | 'Whatsapp' | 'E-mail';

export const share = (type: ShareType) => {
  if (!CAN_USE_DOM) {
    return;
  }
  const domain = CONFIG.domainUrl;
  const titleProp = document.querySelector('meta[property="og:title"]');
  const title = titleProp ? titleProp.getAttribute('content') : '';
  const descriptionProp = document.querySelector('meta[property="og:description"]');
  const description = descriptionProp ? descriptionProp.getAttribute('content') : '';
  const encode = encodeURIComponent;
  const isMobile = !!window.ontouchstart && window.matchMedia('(max-width: 1024px)').matches;
  const text = `${title}\n${description}\n${domain}`;

  if (type === 'Facebook') {
    const fbURL = 'https://www.facebook.com/sharer.php?u=';

    window.open(`${fbURL}${domain}`, 'sharer', 'width=555,height=600');
  }

  if (type === 'Whatsapp') {
    const waShareLink = `https://wa.me/?text=${encode(`${text}`)}`;
    if (!isMobile) {
      window.open(waShareLink, '_blank');
    } else {
      window.location.href = waShareLink;
    }
  }

  if (type === 'E-mail') {
    if (title && description && domain) {
      if (title && domain) {
        window.location.href =
          'mailto:?subject=' +
          encode(title) +
          '&body=' +
          encode(title) +
          '%0D%0A' +
          encode(description + ' ') +
          '%0D%0A%0D%0A' +
          encode(domain);
      }
    }
  }
};
