import React, { useState } from 'react';
import cn from 'classnames';

import Icon from '../Icon';
import { share } from 'client/utils/share';
import { SHARE_CONSTANTS } from 'client/constants';
import { useResponsive } from 'client/hooks';
import { ACTIONS, fireGAEvent, fireShareGAEvent } from 'client/utils/gtag';

import logo from 'client/assets/header/logo.png';
import ynetLogo from 'client/assets/header/ynet_web.png';
import ynetLogoM from 'client/assets/header/ynet_mobile.png';

import css from './Header.module.scss';

export const Header = () => {
  const [isMobile] = useResponsive('MOBILE');
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpenMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  const clickOnShare = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const type = e.currentTarget.dataset.id;

    if (type === 'Facebook' || type === 'Whatsapp' || type === 'E-mail') {
      share(type);

      fireShareGAEvent({ Action: ACTIONS.SHARE, Label: type });
    }
  };

  const onFollowUpClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const type = e.currentTarget.dataset.id;

    if (type) fireShareGAEvent({ Action: ACTIONS.FOLLOW, Label: type });
  };

  const onLogoClick = () => {
    fireGAEvent({ Action: ACTIONS.LOGO_CLICK, Label: ACTIONS.LOGO_CLICK });

    window.open('https://www.bmw.co.il/he/All-Models/m-series.html', '_blank');
  };

  return (
    <header className={cn(css.header, isOpen && css.open, isMobile && css.minified)}>
      <div className={css.rightPart}>
        <a href={URLS.ynetLogo} rel="noreferrer" target="_blank">
          <img src={isMobile ? ynetLogoM : ynetLogo} alt="" className={css.ynetLogo} />
        </a>
        <span>{TEXTS.someOtherText}</span>
        <img src={logo} alt="" className={css.logo} onClick={onLogoClick} />
      </div>
      <div className={css.leftPart}>
        <div className={css.socials}>
          <a
            href={URLS.instagram}
            onClick={onFollowUpClick}
            data-id="Instagram"
            rel="noreferrer"
            target="_blank"
            className={css.instagram}
          >
            <span>{TEXTS.instagramText}</span>
            <Icon type="instagram" className={css.icon} />
          </a>
          <a
            href={URLS.facebook}
            onClick={onFollowUpClick}
            data-id={SHARE_CONSTANTS.FACEBOOK}
            rel="noreferrer"
            target="_blank"
            className={css.facebook}
          >
            <Icon type="facebook" className={css.icon} />
          </a>
        </div>
        <div className={css.devider} />
        <div className={css.shares}>
          <div className={css.envelope} data-id={SHARE_CONSTANTS.MAIL} onClick={clickOnShare}>
            {!isMobile && <span>{TEXTS.envelopeText}</span>}
            <Icon type="envelope" className={cn(css.icon, css.email)} />
          </div>
          <div className={cn(css.shareIcon)} data-id={SHARE_CONSTANTS.FACEBOOK} onClick={clickOnShare}>
            <Icon type="facebook" className={cn(css.icon, css.facebook)} />
          </div>
          <div className={cn(css.shareIcon)} data-id={SHARE_CONSTANTS.WHATSAPP} onClick={clickOnShare}>
            <Icon type="whatsup" className={cn(css.icon, css.whatsup)} />
          </div>
        </div>
        {isMobile && (
          <div className={cn(css.toggleBtn, isOpen && css.open)} onClick={toggleOpenMenu}>
            <span>{TEXTS.envelopeText}</span>
            <Icon type="share" color="#ff7722" className={css.iconToggle} />
          </div>
        )}
      </div>
    </header>
  );
};

const TEXTS = {
  envelopeText: 'שתפו',
  instagramText: 'עקבו אחרינו',
  someOtherText: 'בשיתוף',
};

const URLS = {
  facebook: 'https://www.facebook.com/bmwisraelofficial/?ref=page_internal',
  instagram: 'https://www.instagram.com/bmwisraelofficial/',
  bmwLogo: 'https://www.bmw.co.il/he/All-Models/m-series.html',
  ynetLogo: 'https://www.ynet.co.il',
};
