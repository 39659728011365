import React, { useState } from 'react';
import cn from 'classnames';

import { useResponsive } from 'client/hooks';

import { Header } from 'client/components/common/Header';
import { FirstSection } from './FirstSection';
import { SecondSection } from './SecondSection';
import { ThirdSection } from './ThirdSection';
import { FourthSection } from './FourthSection';
import { GallerySection } from './GallerySection';
import { GalleryImagePopup } from './GalleryImagePopup';

import css from './Intro.module.scss';

interface Props {
  className?: string;
}

const Main: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const [selectedImage, setSelectedImage] = useState<string>('');
  // const [isMobile] = useResponsive('MOBILE');

  const selectGalleryImage = (imgUrl: string) => () => {
    setSelectedImage(imgUrl);
  };

  return (
    <div className={cn(css.main, className)}>
      <Header />
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <GallerySection onImageSelect={selectGalleryImage} />
      <GalleryImagePopup imgUrl={selectedImage} onClose={selectGalleryImage('')} />
    </div>
  );
};

export default Main;
