import React, { FC } from 'react';
import cn from 'classnames';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

import { useResponsive } from 'client/hooks';

import images from './images';
import css from './GallerySection.module.scss';

type Props = {
  onImageSelect: (param: string) => () => void;
};

type ImageWrapperProps = {
  children: JSX.Element;
  startPos?: number;
};

const boxVariant = {
  visible: { opacity: 1, transition: { duration: 1.2 } },
  hidden: { opacity: 0 },
};

const ImageWrapper = ({ children, startPos }: ImageWrapperProps) => {
  const control = useAnimation();
  const [ref, inView] = useInView({ rootMargin: `0px 0px -${startPos ? startPos : 0}% 0px` });

  useEffect(() => {
    if (inView) {
      control.start('visible');
    } else {
      control.start('hidden');
    }
  }, [control, inView]);

  return (
    <motion.div className={css.box} ref={ref} variants={boxVariant} initial="hidden" animate={control}>
      {children}
    </motion.div>
  );
};

export const GallerySection: FC<Props> = ({ onImageSelect }) => {
  const [isMobile] = useResponsive('MOBILE');

  return (
    <section className={css.gallerySection}>
      <div className={css.galleryWrapper}>
        <div className={cn(css.imagesWrapper, css.v1)}>
          <ImageWrapper startPos={isMobile ? 0 : 10}>
            <div
              className={cn(css.image, css.first)}
              onClick={onImageSelect(images.image1Src)}
              style={{ backgroundImage: `url('${images.image1Src}')` }}
            ></div>
          </ImageWrapper>
          <ImageWrapper startPos={isMobile ? 0 : 5}>
            <div
              className={cn(css.image, css.second)}
              onClick={onImageSelect(images.image2Src)}
              style={{ backgroundImage: `url('${images.image2Src}')` }}
            ></div>
          </ImageWrapper>
        </div>
        <div className={cn(css.imagesWrapper, css.v2)}>
          <ImageWrapper startPos={isMobile ? 0 : 10}>
            <div
              className={cn(css.image, css.first)}
              onClick={onImageSelect(images.image3Src)}
              style={{ backgroundImage: `url('${images.image3Src}')` }}
            ></div>
          </ImageWrapper>
          <ImageWrapper startPos={isMobile ? 0 : 5}>
            <div
              className={cn(css.image, css.second)}
              onClick={onImageSelect(images.image4Src)}
              style={{ backgroundImage: `url('${images.image4Src}')` }}
            ></div>
          </ImageWrapper>
        </div>
        <div className={cn(css.imagesWrapper, css.v3)}>
          <ImageWrapper startPos={isMobile ? 0 : 15}>
            <div
              className={css.image}
              onClick={onImageSelect(images.image5Src)}
              style={{ backgroundImage: `url('${images.image5Src}')` }}
            ></div>
          </ImageWrapper>
        </div>
        <div className={cn(css.imagesWrapper, css.v4)}>
          <ImageWrapper startPos={isMobile ? 0 : 15}>
            <div
              className={css.image}
              onClick={onImageSelect(images.image6Src)}
              style={{ backgroundImage: `url('${images.image6Src}')` }}
            ></div>
          </ImageWrapper>
        </div>
        <div className={cn(css.imagesWrapper, css.v1)}>
          <ImageWrapper startPos={isMobile ? 0 : 10}>
            <div
              className={cn(css.image, css.first)}
              onClick={onImageSelect(images.image7Src)}
              style={{ backgroundImage: `url('${images.image7Src}')` }}
            ></div>
          </ImageWrapper>
          <ImageWrapper startPos={isMobile ? 0 : 5}>
            <div
              className={cn(css.image, css.second)}
              onClick={onImageSelect(images.image8Src)}
              style={{ backgroundImage: `url('${images.image8Src}')` }}
            ></div>
          </ImageWrapper>
        </div>
        <div className={cn(css.imagesWrapper, css.v1)}>
          <ImageWrapper startPos={isMobile ? 0 : 10}>
            <div
              className={cn(css.image, css.first)}
              onClick={onImageSelect(images.image9Src)}
              style={{ backgroundImage: `url('${images.image9Src}')` }}
            ></div>
          </ImageWrapper>
          <ImageWrapper startPos={isMobile ? 0 : 5}>
            <div
              className={cn(css.image, css.second)}
              onClick={onImageSelect(images.image10Src)}
              style={{ backgroundImage: `url('${images.image10Src}')` }}
            ></div>
          </ImageWrapper>
        </div>
        <div className={cn(css.imagesWrapper, css.v2)}>
          <ImageWrapper startPos={isMobile ? 0 : 10}>
            <div
              className={cn(css.image, css.first)}
              onClick={onImageSelect(images.image11Src)}
              style={{ backgroundImage: `url('${images.image11Src}')` }}
            ></div>
          </ImageWrapper>
          <ImageWrapper startPos={isMobile ? 0 : 5}>
            <div
              className={cn(css.image, css.second)}
              onClick={onImageSelect(images.image12Src)}
              style={{ backgroundImage: `url('${images.image12Src}')` }}
            ></div>
          </ImageWrapper>
        </div>
        <div className={cn(css.imagesWrapper, css.v3)}>
          <ImageWrapper startPos={isMobile ? 0 : 15}>
            <div
              className={css.image}
              onClick={onImageSelect(images.image13Src)}
              style={{ backgroundImage: `url('${images.image13Src}')` }}
            ></div>
          </ImageWrapper>
        </div>
        <div className={cn(css.imagesWrapper, css.v5)}>
          <ImageWrapper startPos={isMobile ? 0 : 12}>
            <div
              className={css.image}
              onClick={onImageSelect(images.image14Src)}
              style={{ backgroundImage: `url('${images.image14Src}')` }}
            ></div>
          </ImageWrapper>
        </div>
        <div className={cn(css.imagesWrapper, css.v6)}>
          <ImageWrapper startPos={isMobile ? 0 : 12}>
            <div
              className={css.image}
              onClick={onImageSelect(images.image15Src)}
              style={{ backgroundImage: `url('${images.image15Src}')` }}
            ></div>
          </ImageWrapper>
        </div>
        <div className={cn(css.imagesWrapper, css.v5)}>
          <ImageWrapper startPos={isMobile ? 0 : 12}>
            <div
              className={css.image}
              onClick={onImageSelect(images.image16Src)}
              style={{ backgroundImage: `url('${images.image16Src}')` }}
            ></div>
          </ImageWrapper>
        </div>
      </div>
    </section>
  );
};

const TEXTS = {
  title: 'ספורטיביות, השראה, עוצמה - חטיבת ה-M של BMW',
};
