import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { InfoPopup } from './InfoPopup';
import { Player } from 'client/components/common/Player';
import { Icon } from 'client/components/common';

import { ACTIONS, VIDEO_ACTIONS, fireGAEvent, fireGAVideoEvent } from 'client/utils/gtag';
import { episodes_data } from './episodes_data';
import { useResponsive } from 'client/hooks';

import css from './SecondSection.module.scss';

type episodeT = 'first' | 'second' | 'third';

const WORDS_NUMBERS: Record<episodeT, number> = {
  first: 1,
  second: 2,
  third: 3,
};

export const SecondSection = () => {
  const [episode, setEpisode] = useState<episodeT>('first');
  const [showInfo, setShowInfo] = useState<boolean>(true);
  const [isMobile] = useResponsive('MOBILE');
  const playerRef = useRef<HTMLVideoElement>(null);

  const isFirstEpisode = episode === 'first';
  const isSecondEpisode = episode === 'second';
  const isThirdEpisode = episode === 'third';

  const onEpisodeChange = (episode: episodeT) => () => {
    const player = playerRef.current || null;

    if (player) {
      player.load();
      setEpisode(episode);
      setShowInfo(false);
    }
  };

  const onArticleClick = () => {
    fireGAEvent({ Action: ACTIONS.MAIN_ARTICLES_CLICK, Label: episodes_data[episode].info.title });
  };

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.autoplay = false;
    }
  }, []);

  useEffect(() => {
    const player = playerRef.current || null;

    if (player) {
      player.onended = async () => {
        fireGAVideoEvent({ Action: VIDEO_ACTIONS.PLAYED_VIDEO_COMPLETED, Label: episodes_data[episode].info.title });

        if (episode === 'first') {
          setEpisode('second');
        } else if (episode === 'second') {
          setEpisode('third');
        }

        if (player && episode !== 'third') {
          player.load();
          await player.play();

          fireGAVideoEvent({ Action: VIDEO_ACTIONS.PLAY_VIDEO_STARTS, Label: episodes_data[episode].info.title });
        }
      };
    }
  }, [episode]);

  return (
    <section className={css.secondSection} id="second-section">
      <div className={css.title}>
        <span>{TEXTS.title}</span>
      </div>
      <InfoPopup {...episodes_data[episode].info} className={css.info} />
      {isMobile && (
        <div className={css.episodeSwitcher}>
          <div className={css.switchers}>
            <div className={cn(css.switcher, isFirstEpisode && css.active)} onClick={onEpisodeChange('first')}>
              פרק ראשון
            </div>
            <div className={cn(css.switcher, isSecondEpisode && css.active)} onClick={onEpisodeChange('second')}>
              פרק שני
            </div>
            <div className={cn(css.switcher, isThirdEpisode && css.active)} onClick={onEpisodeChange('third')}>
              פרק אחרון
            </div>
          </div>
        </div>
      )}
      <div className={css.playerWrap}>
        <Player
          className={css.player}
          medialLink={episodes_data[episode].video[isMobile ? 'mobile' : 'desktop']}
          isControls
          ref={playerRef}
          videoTitle={episodes_data[episode].info.title}
          autoPlay={false}
          onPlay={setShowInfo}
          poster={episodes_data[episode].posters[isMobile ? 'mobile' : 'desktop']}
        />
      </div>
      <div className={css.underPlayer}>
        <div className={css.videoCast}>
          <div>{TEXTS.upperText}</div>
          {isMobile && <span>|</span>}
          <div>{TEXTS.underText}</div>
        </div>
        <div className={css.videoControls}>
          {!isMobile && (
            <div className={css.episodeSwitcher}>
              <div className={css.switchers}>
                <div className={cn(css.switcher, isFirstEpisode && css.active)} onClick={onEpisodeChange('first')}>
                  פרק ראשון
                </div>
                <div className={cn(css.switcher, isSecondEpisode && css.active)} onClick={onEpisodeChange('second')}>
                  פרק שני
                </div>
                <div className={cn(css.switcher, isThirdEpisode && css.active)} onClick={onEpisodeChange('third')}>
                  פרק אחרון
                </div>
              </div>
            </div>
          )}
          <a
            href={episodes_data[episode].articleUrl}
            onClick={onArticleClick}
            className={css.articleUrl}
            target="_blank"
            rel="noreferrer"
          >
            <span>{episodes_data[episode].btnTitle}</span>
            <Icon type="arrow-left" />
          </a>
          <div className={css.greyLine} />
        </div>
      </div>
    </section>
  );
};

const TEXTS = {
  title: 'פרויקט E46: סדרת וידאו מעוררת השראה על החלום של ד"ר קרייזר',
  upperText: 'Director - Tallis Clarke | Cinematography - Tallis Clarke, Tom Shaked, Yuval Bargil, Harel Madhala ',
  underText: ' Editing - Tallis Clarke | Colour grading - Yuval Aloni',
};
