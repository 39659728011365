import React, { FC } from 'react';

import cn from 'classnames';

import css from './InfoPopup.module.scss';

type Props = {
  subtitle: string;
  className?: string;
};
export const InfoPopup: FC<Props> = ({ subtitle, className }) => {
  return (
    <div className={cn(css.info, className)}>
      <div className={css.subtitle}>
        <span>{subtitle}</span>
      </div>
    </div>
  );
};
