import { IS_DEV } from 'client/constants';
import { CAN_USE_DOM } from 'constants/index';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const hostname = 'bmw_50';

if (CAN_USE_DOM) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    hostname: hostname,
  });
}

export enum VIDEO_ACTIONS {
  PLAYED = 'Played',
  AUTO_START = 'Auto Start',
  PLAY_ADS = 'Play - Ads',
  PLAY_VIDEO_STARTS = 'Play - Video Starts',
  PAUSE = 'Pause',
  RESUME = 'Resume',
  PLAYED_VIDEO_COMPLETED = 'Played - Video Completed',
}

export enum ACTIONS {
  MAIN_ARTICLES_CLICK = 'main articles click',
  SECONDARY_ARTICLES_CLICK = 'secondary articles click',
  TIMELINE_CLICK = 'Timeline click',
  LOGO_CLICK = 'logo click',
  SHARE = 'Share',
  FOLLOW = 'follow us',
}

export const fireGAEvent = ({ Action, Label }: { Action: ACTIONS; Label: string }) => {
  const resultObject = {
    event: 'GA_events',
    Category: 'bmw',
    Action,
    Label,
  };

  return window.dataLayer.push(resultObject);
};

export const fireShareGAEvent = ({ Action, Label }: { Action: ACTIONS; Label: string }) => {
  const resultObject = {
    event: 'share_events',
    Category: 'bmw',
    Action,
    Label,
  };

  return !IS_DEV && window.dataLayer.push(resultObject);
  // return window.dataLayer.push(resultObject);
};

export const fireGAVideoEvent = ({
  Action,
  Label,
  actionsResult = '',
}: {
  Action: VIDEO_ACTIONS;
  Label: string;
  actionsResult?: string;
}) => {
  const resultObject = {
    event: 'GA_events',
    Category: 'bmw',
    Action: `${Action}${actionsResult}`,
    Label,
  };

  return !IS_DEV && window.dataLayer.push(resultObject);
  // return window.dataLayer.push(resultObject);
};
