import image1Src from 'client/assets/gallery/image1.jpg';
import image2Src from 'client/assets/gallery/image2.jpg';
import image3Src from 'client/assets/gallery/image3.jpg';
import image4Src from 'client/assets/gallery/image4.jpg';
import image5Src from 'client/assets/gallery/image5.jpg';
import image6Src from 'client/assets/gallery/image6.jpg';
import image7Src from 'client/assets/gallery/image7.jpg';
import image8Src from 'client/assets/gallery/image8.jpg';
import image9Src from 'client/assets/gallery/image9.jpg';
import image10Src from 'client/assets/gallery/image10.jpg';
import image11Src from 'client/assets/gallery/image11.jpg';
import image12Src from 'client/assets/gallery/image12.jpg';
import image13Src from 'client/assets/gallery/image13.jpg';
import image14Src from 'client/assets/gallery/image14.jpg';
import image15Src from 'client/assets/gallery/image15.jpg';
import image16Src from 'client/assets/gallery/image16.jpg';

const images = {
  image1Src,
  image2Src,
  image3Src,
  image4Src,
  image5Src,
  image6Src,
  image7Src,
  image8Src,
  image9Src,
  image10Src,
  image11Src,
  image12Src,
  image13Src,
  image14Src,
  image15Src,
  image16Src,
};

export default images;
