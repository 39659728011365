import React from 'react';

import { Article } from './Article';
import { Player } from 'client/components/common/Player';

import { articles_data } from './articles-data';

import css from './ThirdSection.module.scss';

export const ThirdSection = () => {
  return (
    <section className={css.thirdSection}>
      <div className={css.title}>{TEXTS.title}</div>
      <div className={css.articles}>
        {articles_data.map((article, index) => (
          <Article {...article} key={`${article.link}${index}`} />
        ))}
      </div>
    </section>
  );
};

const TEXTS = {
  title: 'תכנים נוספים על חטיבת הספורט M',
  videoArticleTitle: 'רכב חשמלי ערכת בידור מרגשת',
  videoArticleSubTitle:
    'התמונות להמחשה בלבד. נתוני טווח הנסיעה החשמלי הינם לפי בדיקות המעבדה. טווח הנסיעה בפועל עשוי להשתנות בהתאם לאבזור הרכב, תנאי הדרך, האקלים, תחזוקת הרכב, מאפייני הנהיגה ובלאי הסוללה המשפיע על קיבולה ועל טווח ה',
};
