/* eslint-disable react/display-name */
import React, { ForwardedRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import cn from 'classnames';

import Icon from '../Icon';
import { VIDEO_ACTIONS, fireGAVideoEvent } from 'client/utils/gtag';

import css from './Player.module.scss';

type Props = {
  medialLink: string;
  isControls?: boolean;
  autoPlay?: boolean;
  muted?: boolean;
  className?: string;
  isWithPlayBtn?: boolean;
  videoTitle?: string;
  onPlay?: any;
  poster?: string;
};

let is25Percents = false;
let is50Percents = false;
let is75Percents = false;
let is90Percents = false;
let is100Percents = false;

export const Player = React.forwardRef((props: Props, ref: ForwardedRef<HTMLVideoElement | null>) => {
  const {
    medialLink,
    isControls = false,
    autoPlay = false,
    muted = false,
    className = '',
    videoTitle = '',
    isWithPlayBtn = false,
    onPlay = () => console.log(''),
    poster = '',
  } = props;
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
  const [isVideoMuted, setIsVideoMuted] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<string>('00:00');
  const timelineRef = useRef<HTMLInputElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  useImperativeHandle<HTMLVideoElement | null, HTMLVideoElement | null>(ref, () => videoRef.current);

  const playVideo = useCallback(async () => {
    const video = videoRef ? videoRef.current : null;

    if (video) {
      if (video.paused) {
        await video.play();
        onPlay(false);

        if (videoTitle) {
          if (video.currentTime === 0) {
            fireGAVideoEvent({ Action: VIDEO_ACTIONS.PLAY_VIDEO_STARTS, Label: videoTitle });
          } else {
            fireGAVideoEvent({ Action: VIDEO_ACTIONS.RESUME, Label: videoTitle });
          }
        }
      } else {
        video.pause();
        onPlay(true);

        if (videoTitle) {
          fireGAVideoEvent({ Action: VIDEO_ACTIONS.PAUSE, Label: videoTitle });
        }
      }
    }
  }, []);

  const muteVideo = useCallback(() => {
    const video = videoRef ? videoRef.current : null;
    if (video) {
      video.muted = !video.muted;
      setIsVideoMuted(video.muted);
    }
  }, []);

  const fireDurationEvent = (videoDuration: number, currentTime: number) => {
    const currentPercentage = Math.round((currentTime / videoDuration) * 100);

    if (currentPercentage === 25 && !is25Percents) {
      console.log('25 percents');
      console.log('_______________________________');
      fireGAVideoEvent({ Action: VIDEO_ACTIONS.PLAYED, Label: videoTitle, actionsResult: ` - 25%` });
      is25Percents = true;
    } else if (currentPercentage === 50 && !is50Percents) {
      console.log('50 percents');
      console.log('_______________________________');
      fireGAVideoEvent({ Action: VIDEO_ACTIONS.PLAYED, Label: videoTitle, actionsResult: ` - 50%` });
      is50Percents = true;
    } else if (currentPercentage === 75 && !is75Percents) {
      console.log('75 percents');
      console.log('_______________________________');
      fireGAVideoEvent({ Action: VIDEO_ACTIONS.PLAYED, Label: videoTitle, actionsResult: ` - 75%` });
      is75Percents = true;
    } else if (currentPercentage === 90 && !is90Percents) {
      console.log('90 percents');
      console.log('_______________________________');
      fireGAVideoEvent({ Action: VIDEO_ACTIONS.PLAYED, Label: videoTitle, actionsResult: ` - 90%` });
      is90Percents = true;
    } else if (currentPercentage === 100 && !is100Percents) {
      console.log('100 percents');
      console.log('_______________________________');
      fireGAVideoEvent({ Action: VIDEO_ACTIONS.PLAYED, Label: videoTitle, actionsResult: ` - 100%` });
      is100Percents = true;
    }
  };

  //events for play/pause the video
  useEffect(() => {
    const video = videoRef ? videoRef.current : null;
    is25Percents = false;
    is50Percents = false;
    is75Percents = false;
    is90Percents = false;
    is100Percents = false;
    if (video) {
      video.onplay = () => {
        setIsVideoPlaying(true);
      };

      video.onpause = () => {
        setIsVideoPlaying(false);
      };

      video.onended = () => {
        setIsVideoPlaying(false);
        if (videoTitle) {
          fireGAVideoEvent({ Action: VIDEO_ACTIONS.PLAYED_VIDEO_COMPLETED, Label: videoTitle });
        }
      };
    }
  }, [videoRef, videoTitle]);

  useEffect(() => {
    const video = videoRef ? videoRef.current : null;

    (async () => {
      if (video && medialLink) {
        video.load();
        await video.play();
      }
    })();
  }, [medialLink]);

  //events for timeline
  useEffect(() => {
    const video = videoRef.current || null;
    const timeline = timelineRef.current || null;

    if (video && timeline) {
      video.ontimeupdate = () => {
        if (timeline) {
          const percentagePosition = (100 * video.currentTime) / video.duration;
          timeline.style.backgroundSize = `${percentagePosition}% 100%`;
          timeline.value = `${percentagePosition}`;

          const time = (+timeline.value * video.duration) / 100;
          const m = Math.floor(time / 60) || 0;
          const minutes = m > 9 ? m : `0${m}`;

          const s = Math.floor(time - +minutes * 60) || 0;
          const seconds = s > 9 ? s : `0${s}`;

          fireDurationEvent(video.duration, time);
          setCurrentTime(`${minutes}:${seconds}`);
        }
      };

      timeline.addEventListener('change', () => {
        if (timeline) {
          const time = (+timeline.value * video.duration) / 100;
          video.currentTime = time;
        }
      });
    }
  }, []);

  useEffect(() => {
    const timeline = timelineRef.current || null;

    if (currentTime === '00:00' && timeline) {
      timeline.style.backgroundSize = `${0}% 100%`;
      // setIsVideoPlaying(false);
    }
  }, [currentTime]);

  return (
    <div className={css.videoPlayer}>
      <video
        ref={videoRef}
        autoPlay={autoPlay}
        controls={false}
        muted={muted}
        poster={poster}
        className={cn(css.player, className)}
      >
        <source src={medialLink} type="video/mp4" />
      </video>
      <div className={cn(css.controls, !isControls && css.hideControls)}>
        <button className={css.mute} onClick={muteVideo}>
          <Icon type={isVideoMuted ? 'unmute' : 'mute'} />
        </button>
        <input type="range" className={css.timeline} ref={timelineRef} />
        <div className={css.currentTime}>{currentTime}</div>
        <button className={css.play} onClick={playVideo}>
          <Icon type={isVideoPlaying ? 'pause' : 'play'} />
        </button>
      </div>
      {isWithPlayBtn && (
        <button className={css.bigPlay} onClick={playVideo}>
          <Icon type={isVideoPlaying ? 'big-pause' : 'big-play'} />
        </button>
      )}
    </div>
  );
});
