type timelineItemT = {
  title: string;
  firstSubtitle: string;
  secondSubtitle?: string;
  date: string;
};

export const timeline_data: Array<timelineItemT> = [
  {
    title: '"גם חברה צריכה לעסוק בספורט": האימרה שבעקבותיה נולדה חטיבת ה-M.',
    firstSubtitle:
      'חבר הדירקטוריון של BMW רוברט לוץ אומר בישיבה: "חברה היא כמו בן אדם, כל עוד היא עוסקת בספורט, היא בכושר, מאומנת היטב, מלאה בהתלהבות ויכולת ביצוע".',
    secondSubtitle: `בהשראת אותה אמרת מחץ במאי באותה שנה קמה סדנת מרוצים ליד המפעל הגדול- חברת בת חדשה ל-BMW שמתמחה במוטורספורט. בראשה עומד נהג המרוצים יוכן נירפש שמושך אחריו שובל של נהגי מרוצים שעומדים להשאיר את חותמם על BMW. כך ביחד עם 35 עובדים הם נותנים גז לנוכחות של חברת המנועים הבווארית על מסלול המרוצים ומקימים את חטיבת ה-M.`,
    date: '1972',
  },
  {
    title: 'עולים במהירות על מסלול המרוצים: יצירת "הבטמוביל".',
    firstSubtitle: `עם 206 כ"ס, מנוע 6 צילינדרים טורי בנפח 3.2 ל' ומשקל עצמי של 1270 ק"ג בלבד – באה לעולם ה-BMW 3.0 C. עד מהרה היא שולטת באליפות אירופה למכוניות סאלון ומציגה שיאים טכנולוגיים. שימוש חופשי במשטחים אירודינאמיים יצרו לה את הכינוי "בטמוביל".`,
    secondSubtitle: `לקראת העונה של 1973 נירפש לוקח את "נבחרת נהגי החלומות שלו" לגן עדן שוויצרי בסנט מוריץ, שם הם עוברים אימונים עם מדריך ספורט ופסיכולוג - כחלק מהחשיבות שייחסה תמיד BMW למפגש אדם-מכונה. הנבחרת הזו, עם סמל מיתוגי של פסים כחול-סגול-אדום ששולט מהמכונית ועד הציוד ובגדי הקבוצה, כובשת בהמשך את המסלולים ורושמת שיאים במרוצי 24 השעות של לה מאן. בין 1973 ל-1979 ה-  BMW 3.0 Cלוקחת שש פעמים את אליפות אירופה ושולטת בסצנה כמעט עשור.
    `,
    date: '1973',
  },
  {
    title: 'חטיבת ה-M מקבלת משימה: להקים "בית ספר לנהגים".',
    firstSubtitle:
      'הרעיון היה כחלק מפילוסופית הממשק בין אדם ומכונה - לשכלל את הביצועים של הנהגים על המסלול. בית הספר סיפר לנהגים בעצמו את המכוניות, כדי ליצור תנאים טכניים זהים, ועד היום יש לחברה צי מכוניות לאימונים. המדריך הראשי הראשון בבית הספר היה "הפיני המעופף", נהג המרוצים האגדי ראונו אלטונן שמעביר את כישוריו הייחודיים לנהגי BMW.',

    date: '1976',
  },
  {
    title: 'כששעטנז הופך לאגדה: ה-M1 מושקת.',
    firstSubtitle: `מכונית ראשונה עם תג M נחשפת למכירה לציבור בתערוכת פריז. כדי להגיע לרגע הזה נדרשה מלאכת הרכבת פאזל. שיתוף פעולה עם למבורגיני עולה על שרטון ושרשרת הייצור נבראת מחדש -  גוף הפלסטיק נור ב-T.I.R, בניית השלדה נמסרת לחברת מרקזי והעיצוב עובר לידיו של ג'יורג'יו ג'יוג'ארו, שמייצר שפה שהקדימה את זמנה. כבר מהרגע הראשון שהיא נוסעת ה-M1 הופכת למכונית הספורט המהירה ביותר בכביש. ההצלחה מסחררת את השוק ובשנה אחת מסופקות 130 מכוניות, כשעוד 300 הזמנות מחכות למימוש.`,
    secondSubtitle: `ה-M1 צוברת תאוצה עוד יותר כשהיא מצטרפת לחימום הסבבים האירופאים של פורמולה1. הצללית הנמוכה שלה רק, 114 ס”מ גובה, נראית טוב על מסלולי המרוץ, והעובדה שנהגי מרוץ מפורסמים נוהגים בה הופכים אותה לעוד יותר נחשקת. גם בזירה התרבותית ה-M1 הופכת לאיקון כשאנדי וורהול עושה ממנה יצירת אומנות על גלגלים.`,
    date: '1978',
  },
  {
    title: 'הסנסציה של הפורמולה 1: BMW יוצרת את אלוף העולם במנועים.',
    firstSubtitle: `יוכן נירפש מפנה את מקומו ליורשו דיטר סטפרט ופול רושה, ששלט עד אז בתחום מנועי המרוצים, ממונה למנהל הטכני, והופך לשחקן מפתח כש-BMW מחליטה להפגין את יכולותיה בממלכה העליונה של הספורט המוטורי – פורמולה 1. כשהם לוקחים ארבעה צילינדרים בנפח 1.5 ליטר בלבד המבוססים על בלוק ייצור סטנדרטי, צוות המומחים של "אשף המנועים" יוצר יחידת כוח של פורמולה 1 עם הספק מדהים של 800 כ"ס. הפיתוח הזה מוכיח את עליונותו על מסלול המרוצים עם ניצחון גדול על המסלול ב-1983: 630 ימים בלבד אחרי הופעתו הראשונה של מנוע הפורמולה 1 הנהג הברזילאי נלסון פיקט זוכה איתו באליפות העולם. עד 1987 קטפה איתו BMW 9 זכיות בגראנד פרי.`,
    date: '1980',
  },
  {
    title: 'הבייבי גדל: רכבים לא רק למסלול, גם לכביש הציבורי.',
    firstSubtitle: `חטיבת ה-M מתרחבת ולוקחת על עצמה עוד תחומי אחריות. עם 380 עובדים היא כבר יותר ממרכז פיתוח מוכוון ביצועים, היא מנהלת סדנאות, מעצבת ובודקת מוצרים של BMW ומציעה הרבה יותר מטכנולוגיית רכב מתוחכמת, כשהיא עונה על המשאלה המתעצמת של לקוחות אניני טעם בעולם הרכב למכוניות בהתאמה אישית, שמהוות נתח משמעותי במכירות. סדנת המרוצים הופכת לגורם ייצור משמעותי בחברה וחטיבת ה-M היא עכשיו הרבה יותר מספורט, היא מייצרת לא רק מכוניות שנולדו על המסלול ועברו לכביש, אלא רכבים שבראש ובראשונה מיועדים לכביש, שמשלבים בתוכם איכויות דינאמיות משובחות.`,
    date: '1983',
  },
  {
    title: '"זאב בעור של כבש": הולדתה של אגדת ה-M5.',
    firstSubtitle: `החטיבה עולה שוב לכותרות בקרב חובבי מכוניות הספורט עם ביצועים גבוהים, מנוע ה-6 ציל' הטורי השש לסל"ד גבוה שנשתל במקור ב-M1, דופק הופעת אורח ב-M635CSi קופה וב-M5. ה-M5 הוא "זאב בעור של כבש" והופך לאגדה עם הספק של 286 כ"ס העולה על הספק המנוע של ה-518i כמעט פי שלושה. בעוד שבמבט ראשון בקושי ניתן היה להבחין בין ה-M5 לבין מקבילתה הייצור הרגיל, המהירות המרבית שלה של 245 קמ"ש הפתיעה לחלוטין נהגים רבים של מכוניות סלון גדולות וספורטיביות.`,
    date: '1984',
  },
  {
    title: 'BMW M3  הפופולרית בהופעת בכורה.',
    firstSubtitle: `כשהחטיבה הקטנה כבר שולטת במסלול  – מוצגת מכונית שהופכת לקלאסיקה ה-BMW M3 .  את הדגם הזה כבר מזהים היטב גם בישראל. דור ה-E30 הפך למנצח הסדרתי על מסלולי המרוצים וגם לא מעט במרוצי רחוב ברחבי העולם. זו ה-M3 המקורית שהחלה את דרכה רק עם 195 כ"ס בשנתה הראשונה. ההצלחה במסלולים מתורגמת גם להצלחה מסחרית עם 5,000 יחידות בגרסת הכביש שנחטפות מהאולמות. גם היום הדור הזה של M3 הוא בין המכוניות הנחשקות ביותר.`,
    date: '1986',
  },
  {
    title: 'דור שני: ה-M5  נבנית גם כמכונית טיורינג.',
    firstSubtitle: `יחידת הכוח שבמקור הייתה בנפח 3.6ל' צומח ל- 3.8 ל'. בהתאמה ההספק עולה מ-315 שזה "לא רע" ל-340 כ"ס שזה כבר באזורים של מכוניות על באותן שנים. עדכון נוסף היה שהמנועים כבר לא נשאו את ה-"M" בקוד הייצור הפנימי שלהם, אלא "S" המציין את מקורן של יחידות הכוח של החטיבה מעתה ואילך. הלקוח יכול לקבוע את ההבדל במבט חטוף, האותיות "BMW" מוחלפות כעת ב-"M Power" על מכסה השסתומים, בדיוק כמו ב-M3 ארבעה צילינדרים. ה-M5 הוצגה בתחילה כסלון ולאחר מכן בתחילת 1992 כטיורינג (סטיישן) ליצירת סינתזה ייחודית של מכונית סדאן-ספורטיבית מגניבה ורכב נוסעים מתוחכם.`,
    date: '1988',
  },
  {
    title: 'מגיע הדור החדש של הסדרה 3.',
    firstSubtitle: `ב-1990, Motorsport כבר עומלת במרץ על ה-M3 הבא. הוא מוצג בשנת 1992 ומבטא את הקו העיצוב החדש והמתון יותר של היצרנית. כבר לא ספוילרים ומסיטי רוח גדולים – אלא מראה מאופק יותר שפונה לניואנסים שמביני דבר קולטים ומעריכים. אחד הדברים האלה הוא צליל המנוע הייחודי המופק ממנוע 3.0 ל' 6 צילינדר טורי המפיק 286 כ"ס. המנוע הזה היה הראשון ב-M בעל תזמון שסתומים משתנה בצד היניקה. היתרון – מומנט גבוה יותר בסל"ד נמוך ובינוני ומכאן זמינות כוח עדיפה. בנוסף, שולב מחשב ניהול חדש, ומהיר במיוחד בעל יכולת לשלוח 20 מיליון פקודות בשניה.`,
    date: '1992',
  },
  {
    title: 'תודה Motorsport שלום BMW M.',
    firstSubtitle: `החטיבה משנה את השם הרשמי שלה ומעתה M היא ללא ספק האות החזקה ביותר בעולם.`,
    date: '1993',
  },
  {
    title: 'עוד כוח ל-M3.',
    firstSubtitle: `רב המכר M3 מקבל עוד יותר כוחות סוס כחלק מעדכון הדגם. הנפח עולה ל-3.2ל' והתפוקה 321 כ"ס. מציב רף מרשים של יותר מ-100 כ"ס לליטר עבור מנוע ללא טורבו. מערכת תזמון השסתומים המשתנה מורחבת ועתה מכסה את שסתומי היניקה וגם את שסתומי הפליטה – לתוספת מומנט וגמישות בסל"ד נמוך וגם אורך נשימה בסיבובים הגבוהים. בנוסף, מקבל ה-M3 גם תיבת הילוכים ידנית חדשה עם 6 הילוכים.`,
    date: '1995',
  },
  {
    title: 'Z3 הופך ל-M מלהיב עם ובלי גג.',
    firstSubtitle: `הדגם הבא שמקבל את הטיפול של M מגיע זמן קצר אחרי שה-Z מגיעה לשוק. שילוב קיצוני של Z3 רודסטר פתוח ל-321 הכ"ס מה-M3 יצר מכונית שרירים ייחודית. זמן קצר אחר כך מוצגת גם גרסת הקופה (גג קשיח) שמיד הופכת למכונית נהיגה טהורה ועם אופי ומראה ייחודיים. הזריזות יוצאת הדופן, היכולת הדינאמית בנהיגה – מציבים רף חדש וגבוה של הנאה מנהיגה.`,
    date: '1997',
  },
  {
    title: 'M5 מקבל מנוע 8 צילינדרים ו-400 כ"ס.',
    firstSubtitle: `הדור השלישי של M5 מוצג עם בשורה של מחוזות דינאמיים חדשים בקבוצת הרכב הזו. בצורה עקבית גם הפעם וויזואלית קשה להבדיל בינו לבין הסדרה 5 המצליחה. זו בדיוק הכוונה. ההבדלים הם בחצאיות האירודינאמיות, חישוקי גלגל גדולים ומפלט עם 4 סופיות. לראשונה מנוע V8 מוצא בית בתוך רכב של M. התפוקה עומדת על 400 כ"ס ו-50 קג"מ – תיבת ההילוכים – ידנית כמובן עם 6 הילוכים.`,
    date: '1998',
  },
  {
    title: 'הדור השלישי של M3 עם שפה חדשה.',
    firstSubtitle: `M זה לא רק מנועים אלא גם בכיול מעודן של השלדה והמתלים – קבלו את הדור השלישי של M3. הפעם שולבו ברכב מעצורים "צפים" עם יתרונות של קירור עדיף ואורך חיים משופר. גם המנוע מחוזק ועולה בהספקו ל-343 כ"ס ו-37 קג"מ. התאוצה ל-100 קמ"ש נושקת ל-5 שניות. למרות השיפור בביצועים ובכיול הפלטפורמה, נותר ה-M3 כלי תחבורה שימושי לחיי היומיום.
    `,
    secondSubtitle: `שנה מאוחר יותר BMW M בונה רכבי מרוץ לליגת American Le Mans ה-M3 GTR הופך לראשון שבו נשתל מנוע V8 ולוקח את הניצחון באליפות המתקיימת בצד האסטרטגי של האוקיאנוס – ארה"ב.
      `,
    date: '2000',
  },
  {
    title: 'שובו של ה-CSL.',
    firstSubtitle: `הפיתוח של גרסה מאד יוצאת דופן של M3 מחזירה שילוש אותיות מן העבר – CSL או Coupé Sport Lightweight – המטרה היא דיאטת משקל רצחנית בה מוחלפים חלקי מרכב רבים: גג, דלתות, קונסולה מרכזית מסיבי פחמן (קרבון) במקום פח. שמשות דקות וקלות יותר ועוד. המנוע מקבל עיסוי נוסף והתפוקה מטפסת ל-360 כ"ס. ה-CSL מיד מוכיחה שהיא מתנהגת באופן מופלא ומקבלת חותמת הכשר בצורה של 7 דקות ו-50 שניות של הקפת הלופ הצפוני במסלול נורבורג. רק 1,383 יחידות יוצרו ואלו נחטפו בזמן קצר במיוחד.`,
    date: '2003',
  },
  {
    title: '10 צילינדרים ל-M5 ו-M6.',
    firstSubtitle: `אחרי שה-M5 קבע שיא מכירות של 20,000 יחידות, BMW M מציבה דגל נוסף בראש פסגת הביצועים. מנוע 10 צילינדרים בנפח 5.0 ל' עם תפוקה של 507 כ"ס, 53 קג"מ וקו אדום סטראטוספרי של 8,000 סל"ד. הביצועים של הדור הרביעי של M5 שוב קובעים תקן חדש בקבוצת הרכב הזו עם זינוק של 25% בתפוקת הכוח ביחס ל-V8 של הדור השלישי. החיבור בין מנוע V10 לתיבת SMG עם 7 הילוכים יצר נתונים כמו 4.7 שניות ל-100 קמ"ש, 15 שניות בלבד ל-200 קמ"ש ומהירות מרבית מוגבלת אלקטרונית ל-250 קמ"ש.`,
    secondSubtitle: `חודשים ספורים אחרי השקת ה-M5 מגיעה לשוק גם ה-M6 המוסיפה למנוע הזה גם אפשרות לשנות כיול שלדה בלחיצת כפתור ומשלבת חלקי קרבון קלי משקל רבים. נהגים שהיו מעוניינים לעלות על מסלול המרוצים עם הרכב שלהם יכלו לבטל את מגבלת ה-250 קמ"ש – אבל רק אחרי שעברו קורס נהיגה מיוחד לפני הסרת המגבלה על ידי המפעל.`,
    date: '2004',
  },
  {
    title: 'דור חדש לרודסטר .BMW M',
    firstSubtitle: `Z4M מרחיבה את תיק הדגמים שלה עם הדור החדש של הרודסטר המתוק. שתי גרסאות המרכב שלו: פתוח ועם גג קשיח נהנים מהשירות של מנוע M3 עם 343 כ"ס. חווית הנסיעה ברודסטר פתוח עם כזה כוח זה לא משהו ששוכחים במהרה.`,
    date: '2006',
  },
  {
    title: 'מנוע V8 מוצא בית ב-M3.',
    firstSubtitle: `אחרי 15 שנים עם מנוע 6 צילינדר טורי ונפלא משולב מנוע V8 לראשונה ב-M3. הנפח הוא 4.0ל', התפוקה 420 כ"ס וכמעט 85% מהמומנט זמינים לנהג על פני טווח נרחב של 6500 סל"ד. הישג הנדסי מרשים ביותר. דיפרנציאל אחורי חדש ומחוזק, שימוש נרחב ברכיבים קלי משקל כולל גג מסיבי-פחמן ומכסה מנוע מאלומיניום.`,
    date: '2007',
  },
  {
    title: 'נמסרת ה-M ה-300,000.',
    firstSubtitle: `BMW M מציינת אבן דרך משמעותית. 30 שנים מתחילת היצור הסדרתי נמסרת ה-M ה-300 אלף במספר. באותה הנשימה, היצע הדגמים של M עולה ל-9 דגמים ובנוסף גם מספקת חבילות אבזור ספורטיביות לסדרה 1, סדרה 3, 5 ו-6 וגם לדגמי הפנאי הזוכים לפופולריות עולה X3 ו-X5`,
    date: '2008',
  },
  {
    title: 'הטורבו חוזר כספק השרירים של העתיד.',
    firstSubtitle: `האבולוציה של דגמי M עם עוד ועוד כוחות סוס לצד העליה בדרישה לדגמי X חזקים מחזירים את מגדשי הטורבו לזירה. פיתוח גרסאות M  של X5 ו-X6 יצר מנוע V8 עתיר ביצועים עם 555 כ"ס הנושם דרך מגדש טורבו כפול-נחירים הממוקם במרכז ה-V בתצורה המכונה "V לוהט" או hotvee. זה תורם לתגובתיות משופרת של הכוח – דבר שכל נהג M יודע להעריך.`,
    secondSubtitle: `באותה השנה, לבקשת הלקוחות, BMW M מפתחת גרסת M3 מיוחדת עם שינויים מאד ממוקדם בכיול השלדה, המנוע, יחסי ההעברה, עיצוב המרכב והפנים. M3 GTS נולדה לכביש ולמסלול כשבחזה פועם מנוע V8 עם 450 כ"ס.`,
    date: '2009',
  },
  {
    title: 'מה זה קטן עם אגרוף גדול? M1.',
    firstSubtitle: `היצע הדגמים ממשיך לגדול עם מהדורת M לסדרה 1 החדשה. באופן קסום הצליחו מהנדסי BMW M להכניס מנוע 6 צילינדר טורבו וטורבו עם 340 כ"ס למרכב הקומפקטי כשעל הדרך הם יוצרים מכונית עם יחס הספק/משקל של 4.4 ק"ג לכל כ"ס וחווית נסיעה פרועה. מבלי לעצור גם ה-M5 מקבל מנוע מעודכן עם 560 כ"ס ומהירות מרבית של 306 קמ"ש. הכי מהיר באוטובהן.`,
    date: '2010',
  },
  {
    title: 'נחשף קונספט M4 קופה.',
    firstSubtitle: `מוקף בדגמים היסטוריים ומבזקי מצלמות עיתונות BMW M מנצלת את הפלטפורמה היוקרתית של תערוכת הרכב הקלאסית של פבל ביץ' כדי להודיע לעולם על הרחבה נוספת של דגמי M הפעם בתצורה של קופה מהודרת. עם חלקי מרכב הנותנים לקופה מראה שרירי ומפוסל יותר, חלקי קרבון קלי משקל וגם הקמבק של מנוע 6 צילינדר טורי. קל יותר ב-10 ק"ג מה-V8 עם תפוקה של 425 כ"ס וכ-50 קג"מ בטווח סל"ד נרחב ביותר. תיבת הילוכים חדשה כפולת מצמדים עם 7 הילוכים וזמן של 3.9 שניות בלבד ל-100 קמ"ש.`,
    date: '2013',
  },
  {
    title: 'M2 מגיעה לעולם לצד X4M.',
    firstSubtitle: `בזמן שיורד השלג מחוץ לאולמות תערוכת הרכב של דטרויט. בפנים, האווירה לוהטת. BMW M מציגה שני דגמים כל חדשים עם עיצוב פנים וחוץ מלוטש. מנוע טורבו 6 ציל' מפיק 365 כ"ס ו-47 קג"מ ומוריד את הכוח לגלגלים אחוריים דרך תיבה כפולת מצמדים מהירת פעולה. 4.2 שניות ל-100 קמ"ש והתנהגות כביש שהיא אתגר שמח לכל נהג נמרץ.
    `,
    secondSubtitle: `בזירת רכבי הפנאי X4M מקבל 355 כ"ס ו-47 קג"מ ומציב רף חדש של ביצועים, אבזור ותחכום בסגמנט תחרותי ומאתגר.`,
    date: '2016',
  },
  {
    title: 'גדול זה גראנד קופה M8.',
    firstSubtitle: `אחרי מיקוד בגדמים הקומפקטיים ודגמי הפנאי, BMW מציגה את סדרה 8 וזמן קצר אחר כך מגיעו גרסאות M של הקופה הגדולה והמהודרת אשר תחת ה"טיפול" של M הופכת לדרקון רושף אש. ביחס לסדרה 8 ה-M8 ארוכה יותר, רחבה יותר, גבוהה יותר ועם בסיס גלגלים ארוך יותר מגרסת הקופה. כל זה יוצר חלל פנים מרווח הרבה יותר לכל היושבים ברכב. בחזית נוהם מנוע טורבו V8 עם 600 כ"ס (או 617 כ"ס בגרסת קומפטישן) ו-76.5 קג"מ המשלב מערכת הזרקת דלק ישירה בלחץ סופר-גבוה ומערכת צינון משופרת. יש גם מפלט מכויל לצלילים הנכונים ויכולת מרשימה להגיע ל-100 קמ"ש ב-3.1 שניות בלבד. ההנעה כפולה לכל הגלגלים עם ניתוב מומנט אקטיבי וגם תיבת הילוכים חזקה במיוחד עם 8 הילוכים.
    `,
    date: '2018',
  },
  {
    title: 'BMW M2 CS קל משקל',
    firstSubtitle: `אפשר לקרא לזה "חזרה למקורות" אחרי סדאנים גדולים ורכבי פנאי, הצגת הסדרה 2 אפשרה ל-M להוציא מכונית נהיגה מחודדת וטהורה עם מנוע 6 צילינדר טורי עם שני טורבו, 450 סוסים, שלדה שכויילה לזריזות וחדות הפניה ומשקל עצמי מופחת בזכות שימוש נרחב בחלקים מסיבי פחמן קלילים. על כביש מפותל או בימי מסלול זו המכונית שנהגים יתקשו להיפרד ממנה. אפשר להבין.`,
    date: '2019',
  },
  {
    title: 'BMW I4 M50 עוברים לחשמל',
    firstSubtitle: `עידן חדש! חשמלי כמובן והרכב החשמלי הראשון מבית M הוא תחנת כוח כזו מרשימה שנהגי מכוניות עם מנועי בעירה פנימית ישקלו להמיר את נאמנותם אחרי מפגש אחד. את הלחץ הקיצוני על המושבים מפיקה תאוצה המתקבלת מכמעט 80 קג"מ ו-544 כ"ס. יש מתלי M מכויילים לנהיגה ספורטיבית, מערכת היגוי ספורטיבית מחודדת וכמובן מעצורים מחוזקים מאד – כי כולם יודעים שבלמים חזקים מנצחים מרוצים. אבן דרך משמעותית בהיסטוריה של M ומבשרת של עתיד עתיר אדרנלין.`,
    date: '2021',
  },
  {
    title: 'XM מביא בשורה של בנזין וחשמל.',
    firstSubtitle: `שנת היובל של BMW M היא גם השנה שבה מוצג XM. פלאג-אין הייבריד עם מנוע בנזין V8 ומנוע חשמלי חזק במיוחד. עם 653 כ"ס ו-81 קג"מ זהו רף חדש של עוצמה ויכולת בסגמנט. ליחידת ההנעה המתקדמת שלושה מצבי נסיעה וכמובן הנעה כפולה חכמה לכל הגלגלים. טווח חשמלי נקי של עד 88 ק"מ ו-140 קמ"ש מאפשרים יוממות ללא פליטת מזהמים בעוד שמנוע הטורבו מספק נסיעה בינעירונית דינאמית ללא חרדות טווח. גרסת LABEL RED של ה-XM היא ה-M הכי חזקה שאי פעם יוצרה עם תפוקה של 748 כ"ס ו-101 קג"מ. כל זה בתוך אריזה היפר-טכנולוגית, מעוצבת ומבוצעת בקדמת האיכות של תעשיית הרכב כולל מערכות סיוע ובטיחות אקטיביות, כיול שלדה דינאמי ועוד.`,
    date: '2022',
  },
];

export const timeline_dataDesktop = {
  0: [
    {
      title: '"גם חברה צריכה לעסוק בספורט": האימרה שבעקבותיה נולדה חטיבת ה-M.',
      firstSubtitle:
        'חבר הדירקטוריון של BMW רוברט לוץ אומר בישיבה: "חברה היא כמו בן אדם, כל עוד היא עוסקת בספורט, היא בכושר, מאומנת היטב, מלאה בהתלהבות ויכולת ביצוע".',
      secondSubtitle: `בהשראת אותה אמרת מחץ במאי באותה שנה קמה סדנת מרוצים ליד המפעל הגדול- חברת בת חדשה ל-BMW שמתמחה במוטורספורט. בראשה עומד נהג המרוצים יוכן נירפש שמושך אחריו שובל של נהגי מרוצים שעומדים להשאיר את חותמם על BMW. כך ביחד עם 35 עובדים הם נותנים גז לנוכחות של חברת המנועים הבווארית על מסלול המרוצים ומקימים את חטיבת ה-M.`,
      date: '1972',
    },
    {
      title: 'עולים במהירות על מסלול המרוצים: יצירת "הבטמוביל".',
      firstSubtitle: `עם 206 כ"ס, מנוע 6 צילינדרים טורי בנפח 3.2 ל' ומשקל עצמי של 1270 ק"ג בלבד – באה לעולם ה-BMW 3.0 C. עד מהרה היא שולטת באליפות אירופה למכוניות סאלון ומציגה שיאים טכנולוגיים. שימוש חופשי במשטחים אירודינאמיים יצרו לה את הכינוי "בטמוביל".`,
      secondSubtitle: `לקראת העונה של 1973 נירפש לוקח את "נבחרת נהגי החלומות שלו" לגן עדן שוויצרי בסנט מוריץ, שם הם עוברים אימונים עם מדריך ספורט ופסיכולוג - כחלק מהחשיבות שייחסה תמיד BMW למפגש אדם-מכונה. הנבחרת הזו, עם סמל מיתוגי של פסים כחול-סגול-אדום ששולט מהמכונית ועד הציוד ובגדי הקבוצה, כובשת בהמשך את המסלולים ורושמת שיאים במרוצי 24 השעות של לה מאן. בין 1973 ל-1979 ה-  BMW 3.0 Cלוקחת שש פעמים את אליפות אירופה ושולטת בסצנה כמעט עשור.
      `,
      date: '1973',
    },
    {
      title: 'חטיבת ה-M מקבלת משימה: להקים "בית ספר לנהגים".',
      firstSubtitle:
        'הרעיון היה כחלק מפילוסופית הממשק בין אדם ומכונה - לשכלל את הביצועים של הנהגים על המסלול. בית הספר סיפר לנהגים בעצמו את המכוניות, כדי ליצור תנאים טכניים זהים, ועד היום יש לחברה צי מכוניות לאימונים. המדריך הראשי הראשון בבית הספר היה "הפיני המעופף", נהג המרוצים האגדי ראונו אלטונן שמעביר את כישוריו הייחודיים לנהגי BMW.',

      date: '1976',
    },
    {
      title: 'כששעטנז הופך לאגדה: ה-M1 מושקת.',
      firstSubtitle: `מכונית ראשונה עם תג M נחשפת למכירה לציבור בתערוכת פריז. כדי להגיע לרגע הזה נדרשה מלאכת הרכבת פאזל. שיתוף פעולה עם למבורגיני עולה על שרטון ושרשרת הייצור נבראת מחדש -  גוף הפלסטיק נור ב-T.I.R, בניית השלדה נמסרת לחברת מרקזי והעיצוב עובר לידיו של ג'יורג'יו ג'יוג'ארו, שמייצר שפה שהקדימה את זמנה. כבר מהרגע הראשון שהיא נוסעת ה-M1 הופכת למכונית הספורט המהירה ביותר בכביש. ההצלחה מסחררת את השוק ובשנה אחת מסופקות 130 מכוניות, כשעוד 300 הזמנות מחכות למימוש.`,
      secondSubtitle: `ה-M1 צוברת תאוצה עוד יותר כשהיא מצטרפת לחימום הסבבים האירופאים של פורמולה1. הצללית הנמוכה שלה רק, 114 ס”מ גובה, נראית טוב על מסלולי המרוץ, והעובדה שנהגי מרוץ מפורסמים נוהגים בה הופכים אותה לעוד יותר נחשקת. גם בזירה התרבותית ה-M1 הופכת לאיקון כשאנדי וורהול עושה ממנה יצירת אומנות על גלגלים.`,
      date: '1978',
    },
    {
      title: 'הסנסציה של הפורמולה 1: BMW יוצרת את אלוף העולם במנועים.',
      firstSubtitle: `יוכן נירפש מפנה את מקומו ליורשו דיטר סטפרט ופול רושה, ששלט עד אז בתחום מנועי המרוצים, ממונה למנהל הטכני, והופך לשחקן מפתח כש-BMW מחליטה להפגין את יכולותיה בממלכה העליונה של הספורט המוטורי – פורמולה 1. כשהם לוקחים ארבעה צילינדרים בנפח 1.5 ליטר בלבד המבוססים על בלוק ייצור סטנדרטי, צוות המומחים של "אשף המנועים" יוצר יחידת כוח של פורמולה 1 עם הספק מדהים של 800 כ"ס. הפיתוח הזה מוכיח את עליונותו על מסלול המרוצים עם ניצחון גדול על המסלול ב-1983: 630 ימים בלבד אחרי הופעתו הראשונה של מנוע הפורמולה 1 הנהג הברזילאי נלסון פיקט זוכה איתו באליפות העולם. עד 1987 קטפה איתו BMW 9 זכיות בגראנד פרי.`,
      date: '1980',
    },
    {
      title: 'הבייבי גדל: רכבים לא רק למסלול, גם לכביש הציבורי.',
      firstSubtitle: `חטיבת ה-M מתרחבת ולוקחת על עצמה עוד תחומי אחריות. עם 380 עובדים היא כבר יותר ממרכז פיתוח מוכוון ביצועים, היא מנהלת סדנאות, מעצבת ובודקת מוצרים של BMW ומציעה הרבה יותר מטכנולוגיית רכב מתוחכמת, כשהיא עונה על המשאלה המתעצמת של לקוחות אניני טעם בעולם הרכב למכוניות בהתאמה אישית, שמהוות נתח משמעותי במכירות. סדנת המרוצים הופכת לגורם ייצור משמעותי בחברה וחטיבת ה-M היא עכשיו הרבה יותר מספורט, היא מייצרת לא רק מכוניות שנולדו על המסלול ועברו לכביש, אלא רכבים שבראש ובראשונה מיועדים לכביש, שמשלבים בתוכם איכויות דינאמיות משובחות.`,
      date: '1983',
    },
    {
      title: '"זאב בעור של כבש": הולדתה של אגדת ה-M5.',
      firstSubtitle: `החטיבה עולה שוב לכותרות בקרב חובבי מכוניות הספורט עם ביצועים גבוהים, מנוע ה-6 ציל' הטורי השש לסל"ד גבוה שנשתל במקור ב-M1, דופק הופעת אורח ב-M635CSi קופה וב-M5. ה-M5 הוא "זאב בעור של כבש" והופך לאגדה עם הספק של 286 כ"ס העולה על הספק המנוע של ה-518i כמעט פי שלושה. בעוד שבמבט ראשון בקושי ניתן היה להבחין בין ה-M5 לבין מקבילתה הייצור הרגיל, המהירות המרבית שלה של 245 קמ"ש הפתיעה לחלוטין נהגים רבים של מכוניות סלון גדולות וספורטיביות.`,
      date: '1984',
    },
  ],
  1: [
    {
      title: 'BMW M3  הפופולרית בהופעת בכורה.',
      firstSubtitle: `כשהחטיבה הקטנה כבר שולטת במסלול  – מוצגת מכונית שהופכת לקלאסיקה ה-BMW M3 .  את הדגם הזה כבר מזהים היטב גם בישראל. דור ה-E30 הפך למנצח הסדרתי על מסלולי המרוצים וגם לא מעט במרוצי רחוב ברחבי העולם. זו ה-M3 המקורית שהחלה את דרכה רק עם 195 כ"ס בשנתה הראשונה. ההצלחה במסלולים מתורגמת גם להצלחה מסחרית עם 5,000 יחידות בגרסת הכביש שנחטפות מהאולמות. גם היום הדור הזה של M3 הוא בין המכוניות הנחשקות ביותר.`,
      date: '1986',
    },
    {
      title: 'דור שני: ה-M5  נבנית גם כמכונית טיורינג.',
      firstSubtitle: `יחידת הכוח שבמקור הייתה בנפח 3.6ל' צומח ל- 3.8 ל'. בהתאמה ההספק עולה מ-315 שזה "לא רע" ל-340 כ"ס שזה כבר באזורים של מכוניות על באותן שנים. עדכון נוסף היה שהמנועים כבר לא נשאו את ה-"M" בקוד הייצור הפנימי שלהם, אלא "S" המציין את מקורן של יחידות הכוח של החטיבה מעתה ואילך. הלקוח יכול לקבוע את ההבדל במבט חטוף, האותיות "BMW" מוחלפות כעת ב-"M Power" על מכסה השסתומים, בדיוק כמו ב-M3 ארבעה צילינדרים. ה-M5 הוצגה בתחילה כסלון ולאחר מכן בתחילת 1992 כטיורינג (סטיישן) ליצירת סינתזה ייחודית של מכונית סדאן-ספורטיבית מגניבה ורכב נוסעים מתוחכם.`,
      date: '1988',
    },
    {
      title: 'מגיע הדור החדש של הסדרה 3.',
      firstSubtitle: `ב-1990, Motorsport כבר עומלת במרץ על ה-M3 הבא. הוא מוצג בשנת 1992 ומבטא את הקו העיצוב החדש והמתון יותר של היצרנית. כבר לא ספוילרים ומסיטי רוח גדולים – אלא מראה מאופק יותר שפונה לניואנסים שמביני דבר קולטים ומעריכים. אחד הדברים האלה הוא צליל המנוע הייחודי המופק ממנוע 3.0 ל' 6 צילינדר טורי המפיק 286 כ"ס. המנוע הזה היה הראשון ב-M בעל תזמון שסתומים משתנה בצד היניקה. היתרון – מומנט גבוה יותר בסל"ד נמוך ובינוני ומכאן זמינות כוח עדיפה. בנוסף, שולב מחשב ניהול חדש, ומהיר במיוחד בעל יכולת לשלוח 20 מיליון פקודות בשניה.`,
      date: '1992',
    },
    {
      title: 'תודה Motorsport שלום BMW M.',
      firstSubtitle: `החטיבה משנה את השם הרשמי שלה ומעתה M היא ללא ספק האות החזקה ביותר בעולם.`,
      date: '1993',
    },
    {
      title: 'עוד כוח ל-M3.',
      firstSubtitle: `רב המכר M3 מקבל עוד יותר כוחות סוס כחלק מעדכון הדגם. הנפח עולה ל-3.2ל' והתפוקה 321 כ"ס. מציב רף מרשים של יותר מ-100 כ"ס לליטר עבור מנוע ללא טורבו. מערכת תזמון השסתומים המשתנה מורחבת ועתה מכסה את שסתומי היניקה וגם את שסתומי הפליטה – לתוספת מומנט וגמישות בסל"ד נמוך וגם אורך נשימה בסיבובים הגבוהים. בנוסף, מקבל ה-M3 גם תיבת הילוכים ידנית חדשה עם 6 הילוכים.`,
      date: '1995',
    },
    {
      title: ' הופך ל-M מלהיב עם ובלי גג.',
      firstSubtitle: `הדגם הבא שמקבל את הטיפול של M מגיע זמן קצר אחרי שה-Z מגיעה לשוק. שילוב קיצוני של Z3 רודסטר פתוח ל-321 הכ"ס מה-M3 יצר מכונית שרירים ייחודית. זמן קצר אחר כך מוצגת גם גרסת הקופה (גג קשיח) שמיד הופכת למכונית נהיגה טהורה ועם אופי ומראה ייחודיים. הזריזות יוצאת הדופן, היכולת הדינאמית בנהיגה – מציבים רף חדש וגבוה של הנאה מנהיגה.`,
      date: '1997',
    },
    {
      title: ' מקבל מנוע 8 צילינדרים ו-400 כ"ס.',
      firstSubtitle: `הדור השלישי של M5 מוצג עם בשורה של מחוזות דינאמיים חדשים בקבוצת הרכב הזו. בצורה עקבית גם הפעם וויזואלית קשה להבדיל בינו לבין הסדרה 5 המצליחה. זו בדיוק הכוונה. ההבדלים הם בחצאיות האירודינאמיות, חישוקי גלגל גדולים ומפלט עם 4 סופיות. לראשונה מנוע V8 מוצא בית בתוך רכב של M. התפוקה עומדת על 400 כ"ס ו-50 קג"מ – תיבת ההילוכים – ידנית כמובן עם 6 הילוכים.`,
      date: '1998',
    },
  ],
  2: [
    {
      title: 'הדור השלישי של M3 עם שפה חדשה.',
      firstSubtitle: `M זה לא רק מנועים אלא גם בכיול מעודן של השלדה והמתלים – קבלו את הדור השלישי של M3. הפעם שולבו ברכב מעצורים "צפים" עם יתרונות של קירור עדיף ואורך חיים משופר. גם המנוע מחוזק ועולה בהספקו ל-343 כ"ס ו-37 קג"מ. התאוצה ל-100 קמ"ש נושקת ל-5 שניות. למרות השיפור בביצועים ובכיול הפלטפורמה, נותר ה-M3 כלי תחבורה שימושי לחיי היומיום.
    `,
      secondSubtitle: `שנה מאוחר יותר BMW M בונה רכבי מרוץ לליגת American Le Mans ה-M3 GTR הופך לראשון שבו נשתל מנוע V8 ולוקח את הניצחון באליפות המתקיימת בצד האסטרטגי של האוקיאנוס – ארה"ב.
      `,
      date: '2000',
    },
    {
      title: 'שובו של ה-CSL.',
      firstSubtitle: `הפיתוח של גרסה מאד יוצאת דופן של M3 מחזירה שילוש אותיות מן העבר – CSL או Coupé Sport Lightweight – המטרה היא דיאטת משקל רצחנית בה מוחלפים חלקי מרכב רבים: גג, דלתות, קונסולה מרכזית מסיבי פחמן (קרבון) במקום פח. שמשות דקות וקלות יותר ועוד. המנוע מקבל עיסוי נוסף והתפוקה מטפסת ל-360 כ"ס. ה-CSL מיד מוכיחה שהיא מתנהגת באופן מופלא ומקבלת חותמת הכשר בצורה של 7 דקות ו-50 שניות של הקפת הלופ הצפוני במסלול נורבורג. רק 1,383 יחידות יוצרו ואלו נחטפו בזמן קצר במיוחד.`,
      date: '2003',
    },
    {
      title: '10 צילינדרים ל-M5 ו-M6.',
      firstSubtitle: `אחרי שה-M5 קבע שיא מכירות של 20,000 יחידות, BMW M מציבה דגל נוסף בראש פסגת הביצועים. מנוע 10 צילינדרים בנפח 5.0 ל' עם תפוקה של 507 כ"ס, 53 קג"מ וקו אדום סטראטוספרי של 8,000 סל"ד. הביצועים של הדור הרביעי של M5 שוב קובעים תקן חדש בקבוצת הרכב הזו עם זינוק של 25% בתפוקת הכוח ביחס ל-V8 של הדור השלישי. החיבור בין מנוע V10 לתיבת SMG עם 7 הילוכים יצר נתונים כמו 4.7 שניות ל-100 קמ"ש, 15 שניות בלבד ל-200 קמ"ש ומהירות מרבית מוגבלת אלקטרונית ל-250 קמ"ש.`,
      secondSubtitle: `חודשים ספורים אחרי השקת ה-M5 מגיעה לשוק גם ה-M6 המוסיפה למנוע הזה גם אפשרות לשנות כיול שלדה בלחיצת כפתור ומשלבת חלקי קרבון קלי משקל רבים. נהגים שהיו מעוניינים לעלות על מסלול המרוצים עם הרכב שלהם יכלו לבטל את מגבלת ה-250 קמ"ש – אבל רק אחרי שעברו קורס נהיגה מיוחד לפני הסרת המגבלה על ידי המפעל.`,
      date: '2004',
    },
    {
      title: 'דור חדש לרודסטר .BMW M',
      firstSubtitle: `Z4M מרחיבה את תיק הדגמים שלה עם הדור החדש של הרודסטר המתוק. שתי גרסאות המרכב שלו: פתוח ועם גג קשיח נהנים מהשירות של מנוע M3 עם 343 כ"ס. חווית הנסיעה ברודסטר פתוח עם כזה כוח זה לא משהו ששוכחים במהרה.`,
      date: '2006',
    },
    {
      title: 'מנוע V8 מוצא בית ב-M3.',
      firstSubtitle: `אחרי 15 שנים עם מנוע 6 צילינדר טורי ונפלא משולב מנוע V8 לראשונה ב-M3. הנפח הוא 4.0ל', התפוקה 420 כ"ס וכמעט 85% מהמומנט זמינים לנהג על פני טווח נרחב של 6500 סל"ד. הישג הנדסי מרשים ביותר. דיפרנציאל אחורי חדש ומחוזק, שימוש נרחב ברכיבים קלי משקל כולל גג מסיבי-פחמן ומכסה מנוע מאלומיניום.`,
      date: '2007',
    },
    {
      title: 'נמסרת ה-M ה-300,000.',
      firstSubtitle: `BMW M מציינת אבן דרך משמעותית. 30 שנים מתחילת היצור הסדרתי נמסרת ה-M ה-300 אלף במספר. באותה הנשימה, היצע הדגמים של M עולה ל-9 דגמים ובנוסף גם מספקת חבילות אבזור ספורטיביות לסדרה 1, סדרה 3, 5 ו-6 וגם לדגמי הפנאי הזוכים לפופולריות עולה X3 ו-X5`,
      date: '2008',
    },
    {
      title: 'הטורבו חוזר כספק השרירים של העתיד.',
      firstSubtitle: `האבולוציה של דגמי M עם עוד ועוד כוחות סוס לצד העליה בדרישה לדגמי X חזקים מחזירים את מגדשי הטורבו לזירה. פיתוח גרסאות M  של X5 ו-X6 יצר מנוע V8 עתיר ביצועים עם 555 כ"ס הנושם דרך מגדש טורבו כפול-נחירים הממוקם במרכז ה-V בתצורה המכונה "V לוהט" או hotvee. זה תורם לתגובתיות משופרת של הכוח – דבר שכל נהג M יודע להעריך.`,
      secondSubtitle: `באותה השנה, לבקשת הלקוחות, BMW M מפתחת גרסת M3 מיוחדת עם שינויים מאד ממוקדם בכיול השלדה, המנוע, יחסי ההעברה, עיצוב המרכב והפנים. M3 GTS נולדה לכביש ולמסלול כשבחזה פועם מנוע V8 עם 450 כ"ס.`,
      date: '2009',
    },
  ],
  3: [
    {
      title: 'מה זה קטן עם אגרוף גדול? M1.',
      firstSubtitle: `היצע הדגמים ממשיך לגדול עם מהדורת M לסדרה 1 החדשה. באופן קסום הצליחו מהנדסי BMW M להכניס מנוע 6 צילינדר טורבו וטורבו עם 340 כ"ס למרכב הקומפקטי כשעל הדרך הם יוצרים מכונית עם יחס הספק/משקל של 4.4 ק"ג לכל כ"ס וחווית נסיעה פרועה. מבלי לעצור גם ה-M5 מקבל מנוע מעודכן עם 560 כ"ס ומהירות מרבית של 306 קמ"ש. הכי מהיר באוטובהן.`,
      date: '2010',
    },
    {
      title: 'נחשף קונספט M4 קופה.',
      firstSubtitle: `מוקף בדגמים היסטוריים ומבזקי מצלמות עיתונות BMW M מנצלת את הפלטפורמה היוקרתית של תערוכת הרכב הקלאסית של פבל ביץ' כדי להודיע לעולם על הרחבה נוספת של דגמי M הפעם בתצורה של קופה מהודרת. עם חלקי מרכב הנותנים לקופה מראה שרירי ומפוסל יותר, חלקי קרבון קלי משקל וגם הקמבק של מנוע 6 צילינדר טורי. קל יותר ב-10 ק"ג מה-V8 עם תפוקה של 425 כ"ס וכ-50 קג"מ בטווח סל"ד נרחב ביותר. תיבת הילוכים חדשה כפולת מצמדים עם 7 הילוכים וזמן של 3.9 שניות בלבד ל-100 קמ"ש.`,
      date: '2013',
    },
    {
      title: ' מגיעה לעולם לצד X4M.',
      firstSubtitle: `בזמן שיורד השלג מחוץ לאולמות תערוכת הרכב של דטרויט. בפנים, האווירה לוהטת. BMW M מציגה שני דגמים כל חדשים עם עיצוב פנים וחוץ מלוטש. מנוע טורבו 6 ציל' מפיק 365 כ"ס ו-47 קג"מ ומוריד את הכוח לגלגלים אחוריים דרך תיבה כפולת מצמדים מהירת פעולה. 4.2 שניות ל-100 קמ"ש והתנהגות כביש שהיא אתגר שמח לכל נהג נמרץ.
    `,
      secondSubtitle: `בזירת רכבי הפנאי X4M מקבל 355 כ"ס ו-47 קג"מ ומציב רף חדש של ביצועים, אבזור ותחכום בסגמנט תחרותי ומאתגר.`,
      date: '2016',
    },
    {
      title: 'גדול זה גראנד קופה M8.',
      firstSubtitle: `אחרי מיקוד בגדמים הקומפקטיים ודגמי הפנאי, BMW מציגה את סדרה 8 וזמן קצר אחר כך מגיעו גרסאות M של הקופה הגדולה והמהודרת אשר תחת ה"טיפול" של M הופכת לדרקון רושף אש. ביחס לסדרה 8 ה-M8 ארוכה יותר, רחבה יותר, גבוהה יותר ועם בסיס גלגלים ארוך יותר מגרסת הקופה. כל זה יוצר חלל פנים מרווח הרבה יותר לכל היושבים ברכב. בחזית נוהם מנוע טורבו V8 עם 600 כ"ס (או 617 כ"ס בגרסת קומפטישן) ו-76.5 קג"מ המשלב מערכת הזרקת דלק ישירה בלחץ סופר-גבוה ומערכת צינון משופרת. יש גם מפלט מכויל לצלילים הנכונים ויכולת מרשימה להגיע ל-100 קמ"ש ב-3.1 שניות בלבד. ההנעה כפולה לכל הגלגלים עם ניתוב מומנט אקטיבי וגם תיבת הילוכים חזקה במיוחד עם 8 הילוכים.
    `,
      date: '2018',
    },
    {
      title: 'BMW M2 CS קל משקל',
      firstSubtitle: `אפשר לקרא לזה "חזרה למקורות" אחרי סדאנים גדולים ורכבי פנאי, הצגת הסדרה 2 אפשרה ל-M להוציא מכונית נהיגה מחודדת וטהורה עם מנוע 6 צילינדר טורי עם שני טורבו, 450 סוסים, שלדה שכויילה לזריזות וחדות הפניה ומשקל עצמי מופחת בזכות שימוש נרחב בחלקים מסיבי פחמן קלילים. על כביש מפותל או בימי מסלול זו המכונית שנהגים יתקשו להיפרד ממנה. אפשר להבין.`,
      date: '2019',
    },
    {
      title: 'BMW I4 M50 עוברים לחשמל',
      firstSubtitle: `עידן חדש! חשמלי כמובן והרכב החשמלי הראשון מבית M הוא תחנת כוח כזו מרשימה שנהגי מכוניות עם מנועי בעירה פנימית ישקלו להמיר את נאמנותם אחרי מפגש אחד. את הלחץ הקיצוני על המושבים מפיקה תאוצה המתקבלת מכמעט 80 קג"מ ו-544 כ"ס. יש מתלי M מכויילים לנהיגה ספורטיבית, מערכת היגוי ספורטיבית מחודדת וכמובן מעצורים מחוזקים מאד – כי כולם יודעים שבלמים חזקים מנצחים מרוצים. אבן דרך משמעותית בהיסטוריה של M ומבשרת של עתיד עתיר אדרנלין.`,
      date: '2021',
    },
    {
      title: 'XM מביא בשורה של בנזין וחשמל.',
      firstSubtitle: `שנת היובל של BMW M היא גם השנה שבה מוצג XM. פלאג-אין הייבריד עם מנוע בנזין V8 ומנוע חשמלי חזק במיוחד. עם 653 כ"ס ו-81 קג"מ זהו רף חדש של עוצמה ויכולת בסגמנט. ליחידת ההנעה המתקדמת שלושה מצבי נסיעה וכמובן הנעה כפולה חכמה לכל הגלגלים. טווח חשמלי נקי של עד 88 ק"מ ו-140 קמ"ש מאפשרים יוממות ללא פליטת מזהמים בעוד שמנוע הטורבו מספק נסיעה בינעירונית דינאמית ללא חרדות טווח. גרסת LABEL RED של ה-XM היא ה-M הכי חזקה שאי פעם יוצרה עם תפוקה של 748 כ"ס ו-101 קג"מ. כל זה בתוך אריזה היפר-טכנולוגית, מעוצבת ומבוצעת בקדמת האיכות של תעשיית הרכב כולל מערכות סיוע ובטיחות אקטיביות, כיול שלדה דינאמי ועוד.`,
      date: '2022',
    },
  ],
};
