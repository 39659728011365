import React, { FC } from 'react';
import cn from 'classnames';

import flagImg from 'client/assets/FourthSection/flag.png';

import css from './TimelineItem.module.scss';

export type childT = 'first' | 'last' | 'second' | '';

type Props = {
  title: string;
  firstSubtitle: string;
  secondSubtitle?: string;
  date: string;
  className?: string;
  onClick: (title: string, date: string, index: number) => void;
  isActive?: boolean;
  child: childT;
  index: number;
};

export const TimelineItem: FC<Props> = (props) => {
  const {
    title,
    firstSubtitle,
    secondSubtitle = '',
    date,
    className = '',
    isActive = false,
    onClick,
    child,
    index,
  } = props;
  return (
    <div className={cn(css.timelineItem, className)}>
      <div className={css.pointerWrap}>
        <div
          className={css.pointer}
          onClick={() => onClick(title, date, index)}
          onTouchStart={() => onClick(title, date, index)}
        >
          {isActive && <div className={css.redDot} />}
        </div>

        <div className={css.pointerTitle}>
          <div className={css.date}>
            <span>{date}</span>
          </div>
          <div className={css.carName}>
            <span>{title}</span>
          </div>
        </div>
      </div>
      <div className={cn(css.hover, isActive && css.activeHover)}>
        <div className={cn(css.info, child && css[`${child}-info-element`])}>
          <div className={css.title}>
            <span>
              {date} - {title}
            </span>
          </div>
          <div className={css.firstSubtitle}>
            <span>{firstSubtitle}</span>
          </div>
          {secondSubtitle && (
            <div className={css.secondSubtitle}>
              <span>{secondSubtitle}</span>
            </div>
          )}
        </div>

        <div className={cn(css.flag, isActive && css.active)}>
          <img src={flagImg} alt="" />
        </div>
      </div>
    </div>
  );
};
